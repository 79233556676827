import { useAnimation, motion } from 'framer-motion';
import { useEffect, PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';

interface InViewObserverProps extends PropsWithChildren {
	seconds?: number;
}
export const InViewObserver = ({ children, seconds }: InViewObserverProps) => {
	const controls = useAnimation();
	const [inViewRef, inView] = useInView({
		triggerOnce: true,
		threshold: 0.2,
	});

	useEffect(() => {
		if (inView) {
			controls.start({ opacity: 1, y: 0 });
		} else {
			controls.start({ opacity: 0, y: 100 });
		}
	}, [controls, inView]);

	return (
		<motion.div ref={inViewRef} animate={controls} initial={{ opacity: 0, y: 100 }} transition={{ delay: seconds }}>
			{children}
		</motion.div>
	);
};
