import { useImageIndex } from '../../../contextApi/ImageIndexContext';
import { SiGrafana, SiPrometheus, SiSentry } from 'react-icons/si';
import { Eye, ErrorInCode, Healthcheck, AdminImpostor } from '../Images';
import { ReactSVG } from 'react-svg';

export const InfrastructureMonitoringModal = () => {
	const { setSelectedImageIndex, fullScreenImage, setFullScreenImage } = useImageIndex();

	return (
		<div className='flex h-full w-full flex-col items-center text-base'>
			<p className='self-start pb-2 text-4xl font-bold'>Infrastructure Monitoring</p>
			<div className='flex  flex-col md:flex-row'>
				<div className=' h-fit md:w-3/4'>
					Do you have multiple servers and services that you want to keep watch on? Increase your observability or even choose full-service stability and security monitoring, including:
					<ul className='mb-4 mt-2 list-inside list-disc md:mb-0 md:whitespace-nowrap'>
						<li>Health checking every service</li>
						<li>Error tracking and transaction counting</li>
						<li>Performance metrics</li>
						<li>Resource usage</li>
						<li>Access logging</li>
					</ul>
				</div>
				<ReactSVG src={Eye} className='rounded-lg fill-light stroke-light object-contain md:ml-2 md:max-h-[180px]' />
			</div>
			<p className='self-start py-1 text-2xl font-semibold'>Take control of your code</p>
			<div className='my-2 flex flex-col items-center justify-center md:mx-5 md:my-0 md:flex-row'>
				<p className='mb-2 md:mb-0'>Writing code is only the first step in software engineering process. Every code has errors. You can take action on that, find broken lines of code or crashes and fix it on time!</p>
				<img width='150' height='150' alt='error example image' src={ErrorInCode} className=' w-fit  cursor-zoom-in rounded-lg object-contain md:ml-5 md:max-h-[100px]' />
			</div>
			<div className=' my-4 flex flex-col-reverse items-center justify-center md:mx-5 md:my-0  md:flex-row'>
				<img width='150' height='150' alt='healtcheck image' src={Healthcheck} className=' w-fit  rounded-lg object-contain md:mr-5 md:max-h-[100px]' />
				<p className='mb-4 mt-8 md:mb-0 md:mt-1'>Healthcheck your applications and keep track of your computing resource consumption. It is important to optimize infrastructure and estimate further requirements.</p>
			</div>
			<div className='my-2 flex  flex-col items-center justify-between md:mx-5 md:my-0 md:flex-row'>
				<p className='mb-4 mt-8 md:mb-0 md:mt-1'>Multiple administrators have access to your servers? Never again will you have to guess who broke it and who had bad intentions.</p>
				<img width='150' height='150' alt='admin impostor image' src={AdminImpostor} className=' w-fit  rounded-lg object-contain md:ml-5 md:max-h-[100px]' />
			</div>
			<p className='my-4 text-xl font-bold md:my-0 md:text-center'>My preferred stack is Sentry, Grafana, Prometheus and Loki.</p>
			<div className='flex w-full max-w-[800px] flex-col items-center justify-between border-b-[1px] py-2 text-6xl md:flex-row'>
				<div className=' w-3/4 rounded-lg bg-neutral-900 py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#c73852] md:w-1/3 '>
					<SiSentry className='w-full' />
				</div>
				<div className=' mx-0 my-4 w-3/4 rounded-lg   bg-neutral-900  py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#ea792a] md:mx-2 md:w-1/3 '>
					<SiGrafana className='w-full' />
				</div>
				<div className=' w-3/4 rounded-lg bg-neutral-900 py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#da4e2a] md:w-1/3 '>
					<SiPrometheus className='w-full' />
				</div>
			</div>
			<div className='my-4 flex w-full flex-col items-center justify-center text-2xl md:my-2 md:flex-row'>
				<p>Are You Intrested?</p>
				<a id='contact' href='#contact' className='ml-2 rounded-md px-1 font-bold transition-colors duration-200 ease-in-out hover:text-[#00c857]'>
					Contact me.
				</a>
			</div>
		</div>
	);
};
