import { useImageIndex } from "../../../contextApi/ImageIndexContext";
import { SiDocker } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import { Fitted, InfrastructureManagement, Stability } from "../Images";
import useTranslation from "../../../customHooks/useTranslation";

export const InfrastructureManagementModal = () => {
  const { setSelectedImageIndex, fullScreenImage, setFullScreenImage } =
    useImageIndex();
  const translate = useTranslation();
  return (
    <div className="flex h-full w-full flex-col">
      <p className=" text-4xl font-bold">
        {translate.offers.infrastructureManagement.title}
      </p>
      <div className=" mb-8 flex h-fit w-full flex-col items-center justify-between md:mb-0 md:flex-row">
        <p className=" md:w-3/4">
          {translate.offers.infrastructureManagement.modal.description1}
        </p>
        <img
          width="150"
          height="150"
          alt="infrastructure management photo"
          src={InfrastructureManagement}
          className="w-full rounded-lg object-contain md:ml-2 md:max-h-[150px] md:w-fit"
        />
      </div>
      <p className="py-2 text-2xl font-semibold md:mt-2">
        {translate.offers.infrastructureManagement.modal.why}
      </p>
      <div className="flex flex-col-reverse items-center justify-center md:mx-5 md:flex-row">
        <p className="my-6 lg:my-0">
          {translate.offers.infrastructureManagement.modal.stability}
        </p>
        <img
          width="150"
          height="150"
          alt="stability image"
          src={Stability}
          className="max-h-[120px] w-fit rounded-lg object-contain md:ml-5"
        />
      </div>
      <div className="flex flex-col items-center justify-center md:mx-5 md:flex-row">
        <img
          width="150"
          height="150"
          alt="fitted image"
          src={Fitted}
          className="max-h-[120px] w-fit rounded-lg object-contain md:mr-5"
        />
        <p>{translate.offers.infrastructureManagement.modal.rightSolution}</p>
      </div>
      {/* TODO */}
      <div className="flex flex-col items-center justify-center">
        <p className="text-center text-lg">
          {translate.offers.infrastructureManagement.modal.myPreferedStack}
        </p>
        <div className=" flex w-full flex-row items-center justify-between border-b-[1px] py-5 text-6xl md:w-[80%]">
          <div className=" mr-2 w-[48%] rounded-lg bg-neutral-900 py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#ebb510] md:mr-0 md:w-[24%]">
            <FaAws className="flex w-full items-center justify-center" />
          </div>
          <div className="mt-0 w-[48%] rounded-lg bg-neutral-900 py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#129fdb] md:mt-2 md:w-[24%]">
            <SiDocker className=" flex w-full items-center justify-center " />
          </div>
        </div>
        <div className="mt-5 flex w-full flex-col items-center justify-center text-2xl md:flex-row">
          <p>
            {translate.offers.infrastructureManagement.modal.interestedQuestion}
          </p>
          <a
            id="contact"
            href="#contact"
            className="ml-2 rounded-md px-1 font-bold transition-colors duration-200 ease-in-out hover:text-[#00c857]"
          >
            {translate.offers.infrastructureManagement.modal.contactMe}
          </a>
        </div>
      </div>
    </div>
  );
};
