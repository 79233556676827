import useTranslation from '../../../customHooks/useTranslation';
import { Scalability, Portability, Maintainability, Automation } from '../Images';

export const ContainerizationModal = () => {
	const translate = useTranslation();

	return (
		<div className='h-full w-full'>
			<p className='mb-2 text-3xl font-bold md:mb-6 md:text-4xl xl:mb-2'>{translate.offers.containerizationAndK8s.title}</p>
			<p className='mb-5'>{translate.offers.containerizationAndK8s.modal.description}</p>

			<p className='text-xl font-semibold'>{translate.offers.containerizationAndK8s.modal.advantages}</p>
			<div className='mb-2 flex flex-col-reverse items-center justify-center md:mx-5 md:min-h-[140px] md:flex-row'>
				<p className='mr-2'>{translate.offers.containerizationAndK8s.modal.scalability}</p>
				<img width='150' height='150' alt='scalability image' src={Scalability} className='w-1/2 md:w-1/5 md:max-w-[150px]' />
			</div>
			<div className='my-2 flex flex-col items-center justify-center md:mx-5 md:min-h-[140px] md:flex-row'>
				<img width='150' height='150' alt='portability image' src={Portability} className='w-1/2 md:w-1/4 md:max-w-[150px]' />
				<p className='ml-2'>{translate.offers.containerizationAndK8s.modal.portability}</p>
			</div>
			<div className='my-2 flex flex-col-reverse items-center justify-center md:mx-5 md:min-h-[140px] md:flex-row'>
				<p className='mr-2'>{translate.offers.containerizationAndK8s.modal.maintainability}</p>
				<img width='150' height='150' alt='maintainability image' src={Maintainability} className='w-1/2 md:w-1/4 md:max-w-[150px]' />
			</div>
			<div className='my-2 flex flex-col items-center justify-center md:mx-5 md:min-h-[140px] md:flex-row'>
				<img width='150' height='150' alt='automation image' src={Automation} className='w-1/2 md:w-1/4 md:max-w-[150px]' />
				<p className='ml-2'>{translate.offers.containerizationAndK8s.modal.automation}</p>
			</div>
		</div>
	);
};
