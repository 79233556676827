import { InViewObserver } from './InVievObserver';

interface ContentDisplayProps {
	content: {
		type: string;
		description: string;
		title: string;
		imageUrl: string;
	};
	index: number;
}
function ContentDisplay({ content, index }: ContentDisplayProps) {
	if (content.type === 'project') {
		return (
			<div className='my-4 h-full'>
				<div className='flex aspect-[2] max-h-[500px] flex-col space-y-4 text-left'>
					<InViewObserver>
						<img width='150' height='150' className=' w-fit rounded-lg object-cover' src={content.imageUrl} alt='content image ' />
					</InViewObserver>
					<InViewObserver>
						<h1 id={`navigation${index}`} className='text-3xl '>
							{content.title}
						</h1>
					</InViewObserver>
					<InViewObserver>
						<p className='text-lg text-gray-400'>{content.description}</p>
					</InViewObserver>
				</div>
			</div>
		);
	}
	return null;
}

export default ContentDisplay;
