import useTranslation from "customHooks/useTranslation";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";

export interface TechPanel {
  icon: JSX.Element;
  header: string;
  description: string;
  comment?: string;
  openPanel: () => void;
  color?: string;
  isSubcontractor?: boolean;
}

export const TechPanel = ({
  icon,
  color,
  openPanel,
  isSubcontractor,
  header,
  description,
  comment,
}: TechPanel) => {
  const [isBackground, setIsBackground] = useState(true);
  const translate = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const iconHex = (
    <div className="polygon relative flex h-24 w-24 items-center justify-center">
      <div className="z-[1]">
        <div
          style={{ color: color }}
          className={`mb-2 flex justify-center text-6xl transition-all duration-300 ease-in-out`}
        >
          <p className="">{icon}</p>
        </div>
        <p className="text-center text-lg font-bold">{header}</p>
      </div>
    </div>
  );

  const closePanel = () => {
    setIsBackground(false);
    setTimeout(() => {
      openPanel();
    }, 300);
  };

  return (
    <>
      <div
        onClick={closePanel}
        className="fixed left-1/2 top-1/2 z-[13] h-fit w-full max-w-[720px] -translate-x-1/2 -translate-y-1/2 px-4 outline-none md:w-fit md:px-0"
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: isBackground ? 1 : 0 }}
          transition={{ duration: 0.3, delay: 0, ease: "easeInOut" }}
          className="bg-dark text-light flex flex-col items-center justify-center rounded-lg p-8"
        >
          <div className="relative">
            {iconHex}
            {isSubcontractor && (
              <>
                <FaExclamationTriangle className="absolute bottom-5 right-0 h-6 w-6 text-orange-400" />
                <span
                  title="Kliknij by poznać szczegóły"
                  onClick={() => {
                    setModalIsOpen(true);
                  }}
                  className="absolute bottom-5 right-0 h-6 w-6 animate-ping cursor-pointer rounded-full bg-orange-400 opacity-75"
                ></span>
              </>
            )}
          </div>
          <motion.p
            initial={{ y: -20, opacity: 0, scale: 0.8 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            transition={{
              type: "spring",
              bounce: 1,
              damping: 20,
              stiffness: 100,
              velocity: 1,
              delay: 0.1,
              ease: "easeInOut",
            }}
            className="text-light mb-8 mt-6 text-center"
          >
            {description}
          </motion.p>
          <motion.p
            initial={{ y: -20, opacity: 0, scale: 0.8 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            transition={{
              type: "spring",
              bounce: 1,
              damping: 20,
              stiffness: 100,
              velocity: 1,
              delay: 0.25,
              ease: "easeInOut",
            }}
            className="text-light-dark text-center"
          >
            {comment}
          </motion.p>
        </motion.div>
      </div>
      <div
        className="fixed left-0 top-0 z-[10] h-screen w-screen cursor-pointer bg-gray-800 opacity-70"
        onClick={closePanel}
      ></div>
    </>
  );
};
