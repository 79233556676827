import LogoFlatDark from '../../assets/logo-flat-dark.svg';
import Poland from '../../assets/Icons/Poland.svg';
import England from '../../assets/Icons/England.svg';
import { useEffect, useState } from 'react';
import useLanguage from '../../customHooks/useLanguage';
import { FaBars, FaUserAlt } from 'react-icons/fa';
import { IoCloseSharp, IoHomeSharp } from 'react-icons/io5';
import { RiListSettingsLine } from 'react-icons/ri';
import { HiBarsArrowUp } from 'react-icons/hi2';
import { IoIosChatboxes } from 'react-icons/io';
import useTranslation from '../../customHooks/useTranslation';
import NavItem from './NavItem';

const Navbar = () => {
	const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
	const [visible, setVisible] = useState(true);
	const { language, setLanguage } = useLanguage();
	const translate = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;
			setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);
			setPrevScrollPos(currentScrollPos);
		};
		window.addEventListener('scroll', handleScroll);
	}, [prevScrollPos]);

	return (
		<>
			<nav className={`fixed z-30 hidden h-16 w-full bg-light text-white transition-all duration-300 ease-in-out md:flex ${visible ? 'top-0' : '-top-16'}`}>
				<div className='flex w-full items-center justify-evenly'>
					<div className='mx-8 flex w-full max-w-[1320px] items-center justify-center'>
						<a className='hidden shrink-0 items-center md:flex xl:mr-16' href='#'>
							<img width='150' height='150' src={LogoFlatDark} className='mx-12 h-12 w-12' alt='logo icon' />
						</a>
						<div className='flex w-full flex-row justify-evenly text-base font-bold text-dark' id='nav-content'>
							<NavItem href='#'>HOME</NavItem>
							<NavItem href='#services'>{translate.services}</NavItem>
							<NavItem href='#technologies'>{translate.technologies}</NavItem>
							<NavItem href='#about'>{translate.about}</NavItem>
							<NavItem href='#contact'>{translate.contact}</NavItem>
						</div>
						<button aria-label='zmiana języka' onClick={() => setLanguage(language === 'en' ? 'pl' : 'en')}>
							{language === 'pl' ? <img width='150' height='150' alt='flag of poland' className='h-8 w-8' src={Poland} /> : <img width='150' height='150' alt='flag of england' className='h-8 w-8' src={England} />}
						</button>
					</div>
				</div>
			</nav>
			<nav className='fixed z-50 mb-8 flex h-16 w-full items-center justify-between bg-light px-8 md:hidden'>
				<div
					onClick={() => {
						setIsOpen(!isOpen);
					}}>
					{isOpen ? <IoCloseSharp size={34} /> : <FaBars size={34} />}
					<div className={` transition-all  ${isOpen ? 'opacity-1 visible' : 'invisible opacity-0'} duration-300 ease-in-out`}>
						<div
							onClick={() => {
								setIsOpen(!isOpen);
							}}
							className='absolute left-0 top-16 w-3/4 justify-evenly space-y-2 bg-light text-lg font-bold text-dark'
							id='nav-content'>
							<div className='flex w-full flex-col px-2'>
								<div className='flex items-center justify-center border-b-[1px] border-gray-300 p-2 py-3'>
									<IoHomeSharp className='mr-2 h-6 w-6' />
									<NavItem href='#'>HOME</NavItem>
								</div>
								<div className='flex items-center justify-center border-b-[1px] border-gray-300 p-2 py-3'>
									<RiListSettingsLine className=' mr-2 h-6 w-6' />
									<NavItem href='#technologies'>TECHNOLOGIES</NavItem>
								</div>
								<div className='flex items-center justify-center border-b-[1px] border-gray-300 p-2 py-3'>
									<HiBarsArrowUp className=' mr-2 h-6 w-6' />
									<NavItem href='#services'>SERVICES</NavItem>
								</div>
								<div className='flex items-center justify-center border-b-[1px] border-gray-300 p-2 py-3'>
									<FaUserAlt className='mr-2 h-6 w-6' />
									<NavItem href='#about'>ABOUT ME</NavItem>
								</div>
								<div className='flex items-center justify-center border-b-[1px] border-gray-300 p-2 py-3'>
									<IoIosChatboxes className='mr-2 h-6 w-6' />
									<NavItem href='#contact'>CONTACT</NavItem>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a className='flex shrink-0 items-center text-dark' href='#'>
					<img width='150' height='150' src={LogoFlatDark} className='mb-1 h-12 w-12' alt='logo icon' />
				</a>
				<button aria-label='zmiana języka' className='ml-2' onClick={() => setLanguage(language === 'en' ? 'pl' : 'en')}>
					{language === 'pl' ? <img width='150' height='150' alt='flag of poland' className='h-6 w-6' src={Poland} /> : <img width='150' height='150' alt='flag of england' className='h-6 w-6' src={England} />}
				</button>
			</nav>
		</>
	);
};

export default Navbar;
