import { ChangeEvent, useState } from 'react';
import { Offer, getOffers } from './Offers';
import { useImageIndex } from '../../contextApi/ImageIndexContext';
import { animated, useSpring } from 'react-spring';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './OffersGallery.css';
import { ReactSVG } from 'react-svg';
import useTranslation from '../../customHooks/useTranslation';

const OffersGallery = () => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
	const { selectedImageIndex, setSelectedImageIndex, fullScreenImage, setFullScreenImage } = useImageIndex();
	const translate = useTranslation();
	const offers = getOffers(translate);
	const settings = {
		dots: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const animation = useSpring({
		opacity: selectedOffer ? 1 : 0,
		config: {
			duration: 300,
			mass: 1,
			tension: 130,
			friction: 25,
		},
	});
	const backgroundAnimation = useSpring({
		opacity: selectedOffer ? 0.7 : 0,
		config: {
			duration: 300,
			mass: 1,
			tension: 130,
			friction: 25,
		},
	});

	function openModal(offer: Offer) {
		setIsOpen(true);
		setSelectedOffer(offer);
		setSelectedImageIndex(0);
		setFullScreenImage(false);
	}

	function closeModal() {
		setSelectedOffer(null);
		setFullScreenImage(false);
		setTimeout(() => {
			setIsOpen(false);
			setSelectedImageIndex(0);
		}, 300);
	}

	function handlePropagation(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.id !== 'contact') {
			e.stopPropagation();
		}
	}

	return (
		<div className='relative mb-72 flex justify-center pt-56'>
			<section className='w-full max-w-[1320px] px-6 text-light'>
				<div className='w-full self-start pb-6 text-3xl font-bold md:text-5xl'>{translate.myServices}</div>
				<div className='px-[5%]'>
					<Slider {...settings}>
						{offers.map(offer => (
							<div key={offer.id} className='h-full cursor-pointer rounded-xl bg-secondary p-4 transition-colors duration-200 ease-in-out hover:bg-secondary-light hover:text-white ' onClick={() => openModal(offer)}>
								<ReactSVG src={offer.imageUrl[0]} className='w-full rounded-lg fill-light stroke-light' />
								<div>
									<h2 className='py-2 text-center text-base font-bold'>{offer.name}</h2>
									<p className='text-sm'>{offer.shortDescription}</p>
								</div>
							</div>
						))}
					</Slider>
				</div>
				<animated.div style={animation} className={`fixed left-1/2 top-1/2 h-[85%] w-3/4 max-w-[960px] -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-black text-white outline-none ${modalIsOpen ? 'z-[13]' : '-z-[1]'}`} onClick={closeModal}>
					{selectedOffer && (
						<div className='w-full overflow-y-auto bg-dark p-8 text-light md:h-full' onClick={() => handlePropagation}>
							{selectedOffer.modal}
						</div>
					)}
				</animated.div>
				{modalIsOpen && <animated.div style={backgroundAnimation} className='fixed left-0 top-0 z-[10] h-screen w-screen cursor-pointer bg-gray-800 opacity-70' onClick={closeModal} />}
				<div className=' fixed left-1/2 top-1/2 z-[15] h-fit w-full -translate-x-1/2 -translate-y-1/2 outline-none md:w-fit'>{fullScreenImage && <img width='150' height='150' onClick={() => setFullScreenImage(!fullScreenImage)} src={selectedOffer?.imageUrl[selectedImageIndex]} alt={selectedOffer?.name} className='h-full w-full cursor-zoom-out rounded-lg bg-black object-contain' />}</div>
				{fullScreenImage && <div className='fixed left-1/2 top-1/2 z-[14] h-[85%] w-3/4 max-w-[1100px] -translate-x-1/2 -translate-y-1/2 cursor-pointer bg-gray-800 opacity-70' onClick={() => setFullScreenImage(false)} />}
			</section>
		</div>
	);
};

export default OffersGallery;
