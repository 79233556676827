import { SiGitlab, SiAnsible, SiDocker, SiKubernetes } from 'react-icons/si';
import { useImageIndex } from '../../../contextApi/ImageIndexContext';
import { Rollback, FailureRate, Clock, Calendars, DissatisfiedAdmin } from '../Images';
import useTranslation from '../../../customHooks/useTranslation';

export const CiCdAutomationModal = () => {
	const translate = useTranslation();
	const { setSelectedImageIndex, fullScreenImage, setFullScreenImage } = useImageIndex();
	return (
		<div className='h-full w-full'>
			<p className='text-4xl font-bold'>{translate.offers.ciCdAutomation.title}</p>
			<div className='flex flex-col items-center justify-between md:flex-row'>
				<p className='md:w-3/4 '>{translate.offers.ciCdAutomation.modal.shortDescription}</p>
				<img width='150' height='150' alt='dissatisfied admin image' src={DissatisfiedAdmin} className='mb-4 w-fit rounded-lg object-contain md:max-h-[140px]' />
			</div>
			{/*<div className='mb-8 flex flex-col md:mb-4'>*/}
			{/*	<img  data-decorative="true"  className='h-full cursor-zoom-in rounded-lg object-contain md:ml-5 md:min-h-[300px]' src={cicdDiagram}/>*/}
			{/*	<p className='md:text-base'>CI/CD enforces automation in building, testing and deployment of software. It makes development and operations much closer. Due to CI/CD productivity is increased, release cycles are shortened, and also reliability is improved.</p>*/}
			{/*</div>*/}
			<div className='flex flex-col md:flex-wrap '>
				<p className=' text-xl'>{translate.offers.ciCdAutomation.modal.implementingCiCd}</p>
				<div className='flex flex-wrap'>
					<div className='flex w-full flex-row items-center justify-center py-2 pr-2 text-base md:w-1/2'>
						<img width='150' height='150' alt='rollback image' className='mr-2 w-1/4' src={Rollback} />
						<p>
							{translate.offers.ciCdAutomation.modal.rollback.label}: {translate.offers.ciCdAutomation.modal.rollback.description}
						</p>
					</div>
					<div className='flex w-full flex-row items-center justify-center py-2 pr-2 text-base md:w-1/2'>
						<img width='150' height='150' alt='calendar image' className='mr-2 w-1/4' src={Calendars} />
						<p>
							{translate.offers.ciCdAutomation.modal.deploymentFrequency.label}: {translate.offers.ciCdAutomation.modal.deploymentFrequency.description}
						</p>
					</div>
					<div className='flex w-full flex-row items-center justify-center py-2 pr-2 text-base md:w-1/2'>
						<img width='150' height='150' alt='clock image' className='mr-2 w-1/4' src={Clock} />
						<p>
							{translate.offers.ciCdAutomation.modal.leadTime.label}: {translate.offers.ciCdAutomation.modal.leadTime.description}
						</p>
					</div>
					<div className='flex w-full flex-row items-center justify-center py-2 pr-2 text-base md:w-1/2'>
						<img width='150' height='150' alt='failure rate image' className='mr-2 w-1/4' src={FailureRate} />
						<p>
							{translate.offers.ciCdAutomation.modal.changeFailureRate.label}: {translate.offers.ciCdAutomation.modal.changeFailureRate.description}
						</p>
					</div>
				</div>
				<div className='flex flex-row flex-wrap items-center justify-center text-xl'>
					{translate.offers.ciCdAutomation.modal.howExampleProcessLooksLike}
					<span
						className='mx-2 cursor-zoom-in font-bold text-green-500'
						onClick={() => {
							setSelectedImageIndex(1);
							setFullScreenImage(!fullScreenImage);
						}}>
						{translate.offers.ciCdAutomation.modal.clickHereForPreview}
					</span>
					<span>{translate.offers.ciCdAutomation.modal.previewOfWorkflow}</span>
				</div>

				<p className='my-4 text-center text-lg'>{translate.offers.ciCdAutomation.modal.myPreferredStack}</p>
				<div className='flex w-full flex-wrap items-center justify-between border-b-[1px] py-4 text-6xl md:flex-row'>
					<div className='mx-auto w-[48%] rounded-lg bg-neutral-900 py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#db6128] md:w-[24%]'>
						<SiGitlab className='flex w-full items-center justify-center' />
					</div>
					<div className='mx-auto w-[48%] rounded-lg bg-neutral-900 py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#e20000] md:mt-0 md:w-[24%]'>
						<SiAnsible className='flex w-full items-center justify-center' />
					</div>
					<div className='mx-auto my-2 w-[48%] rounded-lg bg-neutral-900 py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#2f66d9] md:-my-0 md:w-[24%]'>
						<SiKubernetes className='flex w-full items-center justify-center' />
					</div>
					<div className='mx-auto w-[48%] rounded-lg bg-neutral-900 py-2 transition-colors duration-300 ease-in-out hover:bg-neutral-800 hover:text-[#129fdb] md:w-[24%]'>
						<SiDocker className='flex w-full items-center justify-center' />
					</div>
				</div>
				<div className='mt-2 flex w-full flex-col items-center justify-center py-2 text-2xl md:flex-row'>
					<p>{translate.offers.ciCdAutomation.modal.interestedQuestion}</p>
					<a id='contact' href='#contact' className='ml-2 rounded-md px-1 font-bold transition-colors duration-200 ease-in-out hover:text-[#00c857]'>
						{translate.offers.ciCdAutomation.modal.contactMe}
					</a>
				</div>
			</div>
		</div>
	);
};
