import { createContext, useState, useContext, ReactNode } from 'react';

interface ImageIndexContextType {
	selectedImageIndex: number;
	setSelectedImageIndex: React.Dispatch<React.SetStateAction<number>>;
	fullScreenImage: boolean;
	setFullScreenImage: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImageIndexContext = createContext<ImageIndexContextType | undefined>(undefined);

interface ImageIndexProviderProps {
	children: ReactNode;
}

export const ImageIndexProvider = ({ children }: ImageIndexProviderProps) => {
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	const [fullScreenImage, setFullScreenImage] = useState(false);

	return <ImageIndexContext.Provider value={{ selectedImageIndex, setSelectedImageIndex, fullScreenImage, setFullScreenImage }}>{children}</ImageIndexContext.Provider>;
};

export const useImageIndex = (): ImageIndexContextType => {
	const context = useContext(ImageIndexContext);

	if (!context) {
		throw new Error('useImageIndex must be used within a ImageIndexProvider');
	}

	return context;
};
