export interface ServicePanel {
	isActive: boolean;
	header: JSX.Element;
	description: string;
	translate: {
		infrastructure: {
			button: string;
		};
	};
}

export const ServicePanel = ({ isActive, header, description, translate }: ServicePanel) => (
	<>
		{isActive && (
			<div className='my-8'>
				<p className='text-base font-medium text-dark md:text-lg xl:text-xl'>{header}</p>
				<p className='mb-8 min-h-[50px]'>{description}</p>
				<a href='#contact' className=' rounded bg-primary p-4 text-center font-bold text-white'>
					{translate.infrastructure.button}
				</a>
			</div>
		)}
	</>
);
