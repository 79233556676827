/* eslint-disable react/jsx-no-comment-textnodes */
import { projects } from 'components/Portfolio';
import Typewriter from 'typewriter-effect';
import { ImArrowUpLeft2 } from 'react-icons/im';
import { Link, useParams } from 'react-router-dom';
import ContentDisplay from './ContentDisplay';
import { useSpring, animated } from 'react-spring';
import { useEffect, useState } from 'react';
import Footer from 'components/Footer/Footer';
import { InViewObserver } from './InVievObserver';

function Project() {
	const { id } = useParams();
	const project = projects[id];
	const [isScrolledToTop, setIsScrolledToTop] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0);
		const handleScroll = () => {
			if (window.scrollY === 0) {
				setIsScrolledToTop(true);
				window.removeEventListener('scroll', handleScroll);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	// const slideUpAnimation = useSpring({
	// 	from: { transform: 'translateY(100%) skewY(-5deg)' },
	// 	to: { transform: 'translateY(40%) skewY(-5deg)' },
	// 	config: { duration: 1000 },
	// });

	const scaleAnimation = useSpring({
		from: { transform: 'scale(0)' },
		to: { transform: 'scale(1)' },
		delay: 1000,
		config: { easing: 'cubic-bezier(.455,.03,.515,.955)' },
	});

	function handleTyping(typewriter, parts) {
		setTimeout(() => {
			parts.forEach((part, index) => {
				if (index === 0) {
					typewriter.typeString(`<span class="text-yellow-500">${part}</span>`);
				} else if (index === 1) {
					typewriter.typeString(`<span class="text-green-500">${part}</span>`);
				} else {
					typewriter.typeString(`<span class="text-white">${part}</span>`);
				}
				if (index < parts.length - 1) {
					typewriter.typeString(' ');
				}
			});
			typewriter.start();
		}, 1000);
	}
	const typingString = project?.details?.content?.typing;

	return (
		<div className='relative'>
			{/* Two elements - skew */}
			<animated.div className='absolute w-full h-1/2 origin-top bg-secondary translate-y-[28rem] -skew-y-6'/>
			<animated.div className='absolute top-1/2 w-full h-1/2 bg-secondary'/>

			{project ? (
				<div className='bg-dark'>
					<div className='mx-auto min-h-screen max-w-[1720px] select-none px-[5%] text-white'>
						<div className='flex flex-col justify-between pb-24 md:py-24 md:flex-row max-h-[600px]'>
							<div className='flex flex-col items-center justify-between py-12'>
								<div>
									<div className='flex w-full items-center justify-center rounded-lg bg-red-500 px-2 py-1 md:w-fit'>
										<div className='whitespace-nowrap text-center text-[12px] font-normal leading-none tracking-wide text-black'> &lt; {project.type} /&gt;</div>
									</div>

									<h1 className='my-4 text-left font-medium text-4xl md:text-5xl mr-4'>{project.title}</h1>
								</div>
								<div className='z-[1] flex w-full justify-left'>
									<Link className='z-[1] flex items-center p-2 -ml-2 hover:-translate-y-2 hover:scale-110 hover:shadow-lg hover:bg-secondary hover:text-light-light transition-all duration-300 ease-in-out rounded-md' to={'/#portfolio'}>
										<ImArrowUpLeft2 size={28} className='mr-2 font-bold' />
										Back to Main Page
									</Link>
								</div>
							</div>
							<animated.div style={scaleAnimation}>
								<video autoPlay loop src={project.videoUrl} className='z-[1] aspect-[2] max-h-[500px] w-fit rounded-lg object-cover' />
							</animated.div>
						</div>
						{isScrolledToTop && (
							<div className='mt-24 flex h-full items-start justify-between pb-24 gap-x-16'>
								<div className='sticky top-12 hidden md:block'>
									<InViewObserver>
										<h2 className='mb-4 text-3xl font-bold'>Details</h2>
										{Object.entries(project.details).map(([key, value]) =>
											key !== 'content' ? (
												<div key={key}>
													<p className='mt-2 font-bold'>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
													<p className='font-thin'>{value}</p>
												</div>
											) : null
										)}
										<h3 className='my-4 text-3xl font-bold'>Navigation</h3>
										<div className='flex flex-col space-y-2'>
											{project.details.content.items.map((item, index) => (
												<a key={index} href={`#navigation${index}`}>
													{item.navigation}
												</a>
											))}
										</div>
									</InViewObserver>
								</div>
								<div className='z-[1] flex flex-col '>
									<InViewObserver seconds={1}>
										<div className=' my-4 hidden h-fit text-sm md:flex'>
											<div className='w-fit rounded-l-lg bg-[#5e4fd9] px-4 py-1'>//</div>
											<div className='flex items-center rounded-r-lg bg-[#1e1f22] px-2'>
												<Typewriter
													onInit={typewriter => handleTyping(typewriter, typingString)}
													options={{
														autoStart: true,
														loop: false,
														deleteSpeed: Infinity,
														delay: 40,
														cursor: '',
													}}
												/>
											</div>
										</div>
									</InViewObserver>
									<div className='flex h-full flex-col text-center'>
										{project.details.content.items.map((item, index) => (
											<ContentDisplay index={index} content={item} />
										))}
									</div>
								</div>
							</div>
						)}
					</div>
					<Footer />
				</div>
			) : (
				<p>Project not found.</p>
			)}
		</div>
	);
}

export default Project;
