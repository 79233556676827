interface SocialIcon {
	icon: JSX.Element;
	link: string;
	colorClass: string;
	description: string;
}

function SocialIcon({ icon, link, colorClass, description }: SocialIcon) {
	return (
		<div className={`mx-2 transition-colors duration-200 ${colorClass}`}>
			<a href={link} target='_blank' rel='noreferrer' aria-label={description}>
				{icon}
			</a>
		</div>
	);
}

export default SocialIcon;
