import { ReactNode } from 'react';

interface NavItemProps {
	children: ReactNode;
	href: string;
}
const NavItem = ({ children, href }: NavItemProps) => (
	<a className='w-full no-underline transition-colors duration-300 ease-in-out hover:bg-neutral-400 md:w-fit md:rounded-lg md:p-2 xl:px-4 xl:py-2' href={href}>
		{children}
	</a>
);

export default NavItem;
