import { ReactNode, createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface LanguageContextProps {
	language: string;
	setLanguage: (lang: string) => void;
}
const defaultLanguageContext: LanguageContextProps = {
	language: 'en',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setLanguage: () => {},
};
export const LanguageContext = createContext(defaultLanguageContext);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
	const [language, setLanguage] = useState('en');
	const location = useLocation();

	useEffect(() => {
		const currentPath = location.pathname;
		if (currentPath.includes('/pl')) {
			setLanguage('pl');
		} else if (currentPath.includes('/en')) {
			setLanguage('en');
		}
	}, [location]);

	return <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>;
};
