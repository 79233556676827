import useTranslation from '../../customHooks/useTranslation';
import Skills, { Skill } from './Skills';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { TechPanel } from './TechPanel';

export default function Technologies() {
	const translate = useTranslation();
	const newSkills = Skills();

	return (
		<div className='relative mt-32 flex justify-center pb-72'>
			<section className='mb-12 w-full max-w-[1320px] px-6'>
				<div id='technologies' className='w-full select-none text-3xl font-bold text-light md:text-5xl'>
					{translate.technologies.charAt(0) + translate.technologies.slice(1).toLowerCase()}
				</div>
				<div className='mt-4 text-light'>
					{translate.technology.description1}
					<br />
					{translate.technology.description2}
					<br />
					{translate.technology.description3}
					<br />
				</div>
				<div className='flex gap-x-14'>
					<div className='mt-8 w-full overflow-hidden'>
						<div className='mx-[-40px] flex flex-wrap justify-evenly gap-x-2 gap-y-6 py-4 md:gap-x-4 l:gap-x-6'>
							{newSkills.map(skill => (
								<SkillCard skill={skill}  key={skill.id} />
							))}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

interface SkillCard {
	skill: Skill;
}

function SkillCard({ skill }: SkillCard) {
	const [isHover, setIsHover] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const handleMouseEnter = () => setIsHover(true);
	const openPanel = () => setIsActive(prev => !prev);
	const handleMouseLeave = () => setIsHover(false);

	const iconStyle = {
		color: isHover ? skill.color : '#cacaca',
	};

	return (
		<>
			<motion.div
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				viewport={{ once: true }}
				onClick={openPanel}
				transition={{ delay: 0.10 * skill.id, duration: 0.8 }}
				className={`mx-10 flex w-fit min-w-[100px] cursor-pointer flex-col items-center justify-center rounded-md py-2 text-center
						transition-all duration-300 ease-in-out hover:-translate-y-2 hover:scale-110 hover:bg-secondary hover:text-light-light hover:shadow-lg`}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}>
				<div className={`mb-2 flex text-6xl transition-all duration-300 ease-in-out`} style={iconStyle}>
					{skill.icon}
				</div>
				<p className='text-ml font-semibold text-light'>{skill.name}</p>
			</motion.div>
			{isActive && <TechPanel openPanel={openPanel} color={skill.color} isSubcontractor={skill.isSubcontractor} icon={skill.icon} header={skill.name} description={skill.description} comment={skill.info} />}
		</>
	);
}
