import {
  FaAws,
  FaDocker,
  FaGitAlt,
  FaGitlab,
  FaRedhat,
  FaSymfony,
} from "react-icons/fa";
import {
  SiAnsible,
  SiGrafana,
  SiKubernetes,
  SiPrometheus,
  SiSentry,
} from "react-icons/si";
import useTranslation from "customHooks/useTranslation";

export interface Skill {
  id: number;
  name: string;
  icon: JSX.Element;
  description: string;
  info?: string;
  color?: string;
  isSubcontractor?: boolean;
}

export default function Skills() {
  const translate = useTranslation();
  return [
    {
      id: 0,
      name: "RHEL",
      iconSize: 10,
      icon: <FaRedhat />,
      description: translate.technology.skills.rhel.description,
      info: translate.technology.skills.rhel.info,
      color: "#e20000",
    },
    {
      id: 1,
      name: "Ansible",
      iconSize: 10,
      icon: <SiAnsible />,
      description: translate.technology.skills.ansible.description,
      info: translate.technology.skills.ansible.info,
      color: "#ef5a53",
    },
    {
      id: 2,
      name: "Git",
      iconSize: 10,
      icon: <FaGitAlt />,
      description: translate.technology.skills.git.description,
      info: translate.technology.skills.git.info,
      color: "#f05133 ",
    },
    {
      id: 3,
      name: "GitLab",
      iconSize: 10,
      icon: <FaGitlab />,
      description: translate.technology.skills.gitLab.description,
      info: translate.technology.skills.gitLab.info,
      color: "#db6128",
    },
    {
      id: 4,
      name: "Docker",
      iconSize: 10,
      icon: <FaDocker />,
      description: translate.technology.skills.docker.description,
      info: translate.technology.skills.docker.info,
      isSubcontractor: false,
      color: "#2f66d9",
    },
    {
      id: 5,
      name: "Kubernetes",
      iconSize: 10,
      icon: <SiKubernetes />,
      description: translate.technology.skills.kubernetes.description,
      info: translate.technology.skills.kubernetes.info,
      color: "#2f66d9",
    },
    {
      id: 6,
      name: "Sentry",
      iconSize: 10,
      icon: <SiSentry />,
      description: translate.technology.skills.sentry.description,
      info: translate.technology.skills.sentry.info,
      color: "#775796",
    },
    {
      id: 7,
      name: "Prometheus",
      iconSize: 10,
      icon: <SiPrometheus />,
      description: translate.technology.skills.prometheus.description,
      info: translate.technology.skills.prometheus.info,
      color: "#e75733",
    },
    {
      id: 8,
      name: "Grafana",
      iconSize: 10,
      icon: <SiGrafana />,
      description: translate.technology.skills.grafana.description,
      info: translate.technology.skills.grafana.info,
      color: "#eb8326",
    },
    {
      id: 9,
      name: "AWS",
      iconSize: 10,
      icon: <FaAws />,
      description: translate.technology.skills.aws.description,
      info: translate.technology.skills.aws.info,
      color: "#f29100",
    },
    {
      id: 10,
      name: "Symfony",
      iconSize: 10,
      icon: <FaSymfony />,
      description: translate.technology.skills.symfony.description,
      info: translate.technology.skills.symfony.info,
      color: "#ffffff",
    },
  ];
}
