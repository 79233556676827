import React, { useEffect, useRef, useState } from 'react';
import './contact.css';
import { AiOutlineRightCircle } from 'react-icons/ai';
import { FcApproval } from 'react-icons/fc';
import { useSpring, animated, config } from 'react-spring';
import Typewriter from 'typewriter-effect';
import useTranslation from '../../customHooks/useTranslation';

type FormState = {
	name: string;
	surname?: string;
	phone: string;
	email: string;
	description: string;
	isSubmitted?: boolean;
	emailError?: string;
	phoneError?: string;
};

const initialState: FormState = {
	name: '',
	phone: '',
	surname: '',
	email: '',
	description: '',
	isSubmitted: false,
	emailError: '',
	phoneError: '',
};

function Contact() {
	const [formState, setFormState] = useState<FormState>(initialState);
	const [step, setStep] = useState(0);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const nameRef = useRef<HTMLInputElement>(null);
	const translate = useTranslation();
	const animateIcon = useSpring({
		rotate: showConfirmation ? 0 : 90,
		opacity: showConfirmation ? 1 : 0,
		scale: showConfirmation ? 1 : 0,
		delay: 300,
		config: config.stiff,
	});

	useEffect(() => {
		if (step === 4) {
			const timer = setTimeout(() => {
				setShowConfirmation(true);
			}, 3500);

			return () => clearTimeout(timer);
		}
	}, [step]);

	const handleFormClick = () => {
		if (step === 0 && nameRef.current) {
			nameRef.current.focus();
		}
	};
	const validateEmail = (email: string) => {
		const emailRegEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		return emailRegEx.test(email);
	};

	const validatePhone = (phone: string) => {
		// const phoneRegEx = /^\d{9}$/;
		const phoneRegEx = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
		return phoneRegEx.test(phone);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormState({
			...formState,
			[e.target.name]: e.target.value,
			emailError: e.target.name === 'email' && !validateEmail(e.target.value) ? translate.emailWrong : '',
			phoneError: e.target.name === 'phone' && !validatePhone(e.target.value) ? translate.phoneWrong : '',
		});
	};

	const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setFormState({
			...formState,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (step === 4) {
			try {
				const fullName = `${formState.name} ${formState.surname}`;
				const updatedFormState = { ...formState, name: fullName };
				delete updatedFormState.emailError;
				delete updatedFormState.isSubmitted;
				delete updatedFormState.phoneError;
				delete updatedFormState.surname;

				const response = await fetch('https://api.pfaff.app/', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(updatedFormState),
				});
				if (response.ok) {
					setFormState({ ...formState, isSubmitted: true });
				} else {
					console.error('Error sending data to the API:', response.status, response.statusText);
				}
			} catch (error) {
				console.error('An error occurred:', error);
			}
		} else {
			setStep(prev => prev + 1);
		}
	};

	return (
		<div className='relative my-32 flex justify-center '>
			<section className='mb-12 w-full max-w-[1320px] px-6 text-sm text-green-400 s:mb-0 md:text-lg lg:text-2xl'>
				<div id='contact' className='w-full select-none text-3xl font-bold text-light md:text-5xl '>
					{translate.interestedForm}
				</div>
				<div className='flex h-full w-full justify-center font-mono md:min-h-[500px]'>
					<div onClick={handleFormClick} className='relative my-6 h-full w-full rounded-t-lg bg-dark-dark shadow-sm shadow-gray-200 '>
						<div className='relative h-8 w-full rounded-t-lg bg-[#e4e4e4] '>
							<p className='absolute top-1/2 w-full -translate-y-1/2 text-center text-base text-black'>{translate.terminalLabel}</p>
							<p className='absolute left-2 top-[30%] h-3 w-3 rounded-full bg-[#ff605e]' />
							<p className='absolute left-6 top-[30%] h-3 w-3 rounded-full bg-[#ffbc4f]' />
							<p className='absolute left-10 top-[30%] h-3 w-3 rounded-full bg-[#00c857]' />
						</div>
						<form className='ml-2 min-h-[300px] '>
							<span className='flex items-center overflow-hidden whitespace-nowrap'>
								User: Client <p className='mx-2 text-[10px] text-violet-400 md:text-lg lg:text-2xl'> MINGW64</p> <p className='mr-2 text-orange-400'> ~</p>
								{formState.isSubmitted ? (
									<Typewriter
										options={{
											strings: [`Thank you ${formState.name}`],
											autoStart: true,
											loop: false,
											deleteSpeed: Infinity,
											cursorClassName: 'hidden',
										}}
									/>
								) : (
									<Typewriter
										options={{
											strings: ['if=/dev/zero of=/dev/sda', 'rm -rf --no-preserve-root /', 'cd /home/user', 'touch example.txt', 'nano example.txt', 'rm example.txt', 'mkdir new_directory', 'rmdir new_directory', 'sudo apt update', 'sudo apt upgrade', 'cat /etc/os-release', 'uname -r', 'df -h', 'top', 'exit'],
											autoStart: true,
											loop: true,
										}}
									/>
								)}
							</span>
							{formState.isSubmitted ? (
								<div className='min-h-[200px]'>
									<Typewriter
										options={{
											strings: ["Root: Checking...<br/>Root: Verify...<br/>Root: ready in 701 ms...<br/>Root: that's all. exit terminal."],
											autoStart: true,
											loop: false,
											delay: 10,
											deleteSpeed: Infinity,
											cursorClassName: 'hidden',
										}}
									/>
									{showConfirmation && (
										<div className='mx-2 flex h-full flex-col items-center justify-center pt-4 md:text-lg'>
											<p>
												{translate.confirmation1} <b>{translate.confirmation2}</b>
											</p>
											<animated.div style={animateIcon}>
												<FcApproval size={84} />
											</animated.div>
										</div>
									)}
								</div>
							) : (
								<>
									{step >= 0 && (
										<div className='flex w-full'>
											<label htmlFor='name' className='mr-2'>
												{translate.firstName} &gt;
											</label>
											<input ref={nameRef} id='name' autoComplete='off' name='name' value={formState.name} onChange={handleInputChange} className='input-terminal w-1/2 border-0 bg-inherit' />
										</div>
									)}
									{step >= 1 && (
										<div className='flex w-full'>
											<label htmlFor='surname' className='mr-2'>
												{translate.lastName} &gt;
											</label>
											<input id='surname' autoComplete='off' autoFocus name='surname' value={formState.surname} onChange={handleInputChange} className='input-terminal w-1/2 border-0 bg-inherit' />
										</div>
									)}
									{step >= 2 && (
										<>
											<div className='flex w-full'>
												<label htmlFor='phone' className='mr-2'>
													{translate.phoneNumber} &gt;
												</label>
												<input id='phone' autoComplete='off' autoFocus name='phone' value={formState.phone} onChange={handleInputChange} className='input-terminal w-1/2 border-0 bg-inherit' />
											</div>
											{formState.phoneError && <div className='text-start text-base text-red-300'>{formState.phoneError}</div>}
										</>
									)}
									{step >= 3 && (
										<>
											<div className='flex w-full'>
												<label htmlFor='email' className='mr-2 w-fit'>
													{translate.email} &gt;
												</label>
												<input id='email' autoComplete='off' autoFocus name='email' value={formState.email} onChange={handleInputChange} className='input-terminal w-1/2 border-0 bg-inherit' />
											</div>
											{formState.emailError && <div className='text-start text-base text-red-300'>{formState.emailError}</div>}
										</>
									)}
									{step >= 4 && (
										<div className='flex w-full'>
											<label htmlFor='description' className='mr-2 w-fit'>
												{translate.description} &gt;
											</label>
											<textarea autoComplete='off' id='description' autoFocus name='description' value={formState.description} onChange={handleTextAreaChange} className='input-terminal min-h-[150px] w-3/4 resize-none scroll-m-0 overflow-hidden border-0 bg-inherit' />
										</div>
									)}

									<button aria-label='send' disabled={(!formState.name && step === 0) || (!formState.surname && step === 1) || (!validatePhone(formState.phone) && step === 2) || (!validateEmail(formState.email) && step === 3)} onClick={handleSubmit} type='submit' className='absolute bottom-2 right-2 border-0 disabled:opacity-30'>
										<AiOutlineRightCircle size={44} />
									</button>
								</>
							)}
						</form>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Contact;
