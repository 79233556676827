import useTranslation from "../../customHooks/useTranslation";

function Footer() {
	const translate = useTranslation();

	return (
		<div className='relative flex justify-center bg-dark-dark text-light'>
			<section className='min-h-48 flex h-full w-full max-w-[452px] md:max-w-[1320px] flex-col items-center justify-center px-[5%] pb-8 pt-3 md:flex-row md:justify-evenly'>
				<div className='mt-5 flex min-w-fit flex-row justify-center text-sm md:w-1/4 md:flex-col'>
					<div className='mr-5'>
						<p className='text-base font-semibold md:text-lg'>Kamil Pfaff Konsulting IT</p>
						<p>Kasztelańska 4, 43-200 Pszczyna</p>
					</div>
					<div>
						<p className='leading-6'>NIP 638-185-36-29</p>
						<p>REGON 525526840</p>
					</div>
				</div>
				<div className='mt-5 md:w-1/3'>
					{translate.designedMessage}
					<a href='https://pfaff.app' className='mx-1 font-bold text-light-dark transition-colors duration-200 ease-in-out hover:text-light-light'>
						Kamil Pfaff
					</a>
					{translate.designedMessageConnector}
					<a href='https://kamildrozdz.pl/' className='mx-1 font-bold text-light-dark transition-colors duration-200 ease-in-out hover:text-light-light'>
						Kamil Dróżdż
					</a>
					.
				</div>
			</section>
		</div>
	);
}

export default Footer;
