import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LanguageProvider } from './contextApi/LanguageContext';
import MainPage from './pages/MainPage';
import Project from './pages/projects/Project';

const rootElement = document.getElementById('root');

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<Router>
			<LanguageProvider>
				<Routes>
					<Route path='/:language' element={<MainPage />} />
					<Route path='/' element={<MainPage />} />
					<Route path='/projects/:id' element={<Project />} />
				</Routes>
			</LanguageProvider>
		</Router>
	);
} else {
	console.error("Couldn't find root element");
}
