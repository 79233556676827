import { Eye, CiCd, InfrastructureManagement, ItConsulting, Containerization } from './Images';
import cicdDiagram from './Images/CiCd/ci-cd-diagram.png';

import { CiCdAutomationModal, ContainerizationModal, InfrastructureManagementModal, InfrastructureMonitoringModal, ItConsultancyModal } from './Modals';

export interface Offer {
	id: number;
	name: string;
	imageUrl: string[];
	shortDescription: string;
	modal?: JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOffers = (translate:any )=> [
	{
		id: 1,
		name: translate.offers.infrastructureMonitoring.title,
		imageUrl: [Eye],
		shortDescription: translate.offers.infrastructureMonitoring.shortDescription,
		modal: <InfrastructureMonitoringModal />,
	},
	{
		id: 2,
		name: translate.offers.ciCdAutomation.title,
		imageUrl: [CiCd, cicdDiagram],
		shortDescription: translate.offers.ciCdAutomation.shortDescription,
		modal: <CiCdAutomationModal />,
	},
	{
		id: 3,
		name: translate.offers.containerizationAndK8s.title,
		imageUrl: [Containerization],
		shortDescription: translate.offers.containerizationAndK8s.shortDescription,
		modal: <ContainerizationModal />,
	},
	{
		id: 4,
		name: translate.offers.infrastructureManagement.title,
		imageUrl: [InfrastructureManagement],
		shortDescription: translate.offers.infrastructureManagement.shortDescription,
		modal: <InfrastructureManagementModal />,
	},
	{
		id: 5,
		name: translate.offers.itConsultancy.title,
		imageUrl: [ItConsulting],
		shortDescription: translate.offers.itConsultancy.shortDescription,
		modal: <ItConsultancyModal />,
	},
];
