import { Complexity, Consulting, ServiceRange } from '../../assets/Icons';
import useTranslation from '../../customHooks/useTranslation';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function WorkScope() {
	const translate = useTranslation();

	const [ref1, inView1] = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});

	const [ref2, inView2] = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});

	const [ref3, inView3] = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});

	return (
		<div className='relative mb-56 mt-4 flex flex-col items-center justify-center'>
			<div className='z-[2] w-full'>
				<div className='z-[2] w-full skew-y-[0.5deg] bg-secondary py-6'>
					<motion.div ref={ref1} animate={{ opacity: inView1 ? 1 : 0 }} transition={{ duration: 0.3 }} id='scope' className='mx-auto w-full max-w-[1320px] -skew-y-[0.5deg] select-none px-6 pb-4 text-start text-2xl font-bold text-light md:text-5xl'>
						{translate.fixDetails}
					</motion.div>
				</div>
			</div>
			<div className='relative z-[3] -mt-8 flex w-full skew-y-[0.5deg] justify-center overflow-hidden bg-secondary  md:py-8'>
				<div className='my-4 flex max-w-[1320px]  -skew-y-[0.5deg] flex-col justify-evenly text-light md:flex-row'>
					<motion.div ref={ref1} animate={{ opacity: inView1 ? 1 : 0 }} transition={{ duration: 0.5 }} className='mx-8 rounded-xl bg-secondary-light py-4 md:w-1/4'>
						<p className=' text-center text-xl font-bold md:z-0'>{translate.serviceRange}</p>
						<p className='mx-auto mt-4 w-[90%] px-2 text-justify md:min-h-[300px] l:min-h-[200px] '>{translate.serviceRangeContent}</p>
						<div className='flex w-full justify-center'>
							<img width='150' height='150' alt='service range image' src={ServiceRange} className='mt-8 w-1/2 max-w-[150px] pb-4 md:mt-0' />
						</div>
					</motion.div>
					<motion.div ref={ref2} animate={{ opacity: inView2 ? 1 : 0 }} transition={{ duration: 0.5, delay: 0.5 }} className='mx-8 my-4 rounded-xl bg-secondary-light py-4 md:my-0 md:w-1/4'>
						<p className='text-center text-xl font-bold'>{translate.complexity}</p>
						<p className=' mx-auto mt-4 w-[90%] self-stretch px-2 text-justify md:min-h-[300px] l:min-h-[200px]'>{translate.complexityContent}</p>
						<div className='flex w-full justify-center'>
							<img width='150' height='150' alt='complexity image' src={Complexity} className='mt-8 w-1/2 max-w-[150px] pb-4 md:mt-0' />
						</div>
					</motion.div>
					<motion.div ref={ref3} animate={{ opacity: inView3 ? 1 : 0 }} transition={{ duration: 0.5, delay: 1 }} className='mx-8 rounded-xl bg-secondary-light py-4 md:w-1/4'>
						<p className='text-center text-xl font-bold'>{translate.consulting}</p>
						<p className='mx-auto mt-4 w-[90%] px-2 text-justify md:min-h-[300px] l:min-h-[200px] '>{translate.consultingContent}</p>
						<div className='flex w-full justify-center'>
							<img width='150' height='150' alt='consulting image' src={Consulting} className='mt-8 w-1/2 max-w-[150px] pb-4 md:mt-0' />
						</div>
					</motion.div>
				</div>
			</div>
		</div>
	);
}

export default WorkScope;
