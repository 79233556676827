import {useImageIndex} from "../../../contextApi/ImageIndexContext";

export const ItConsultancyModal = () => {
    const {setSelectedImageIndex, fullScreenImage, setFullScreenImage} = useImageIndex();

    return (
        <div className='h-full w-full'>
        </div>
    );
}
