export const en = {
	mainView: {
		name: 'Kamil Pfaff',
		job: 'DevOps Engineer',
		texts: [
			{
				// plan
				id: 1,
				pre: 'I am providing',
				text: 'IT Consultancy',
				post: 'services.',
			},
			{
				// code
				id: 2,
				pre: 'I can implement',
				text: 'software solutions',
				// text: 'Infrastructure Automation',
				post: 'that you need.',
			},
			{
				// build/test
				id: 3,
				pre: 'I will',
				text: 'automate',
				post: 'your processes.',
			},
			{
				// release/deploy
				id: 4,
				pre: 'I will',
				text: 'containerize',
				post: 'your environment.',
			},
			{
				// operate
				id: 5,
				pre: 'I am providing',
				text: 'server management', // Infrastructure Management
				post: 'services.',
			},
			{
				// monitor
				id: 6,
				pre: 'I am implementing',
				text: 'modern monitoring',
				post: 'solutions.',
			},
		]
	},
	contact: 'CONTACT',
	about: 'ABOUT ME',
	services: 'SERVICES',
	technologies: 'TECHNOLOGIES',
	whoAmI: 'Who am I?',
	aboutMe: 'Hi! I am Kamil, young and ambitious DevOps engineer with experience in backend development and IT administration. Big fan of creating simple solutions for real-world problems. I am not afraid of any tasks and problems - I believe that I can do everything, and the only thing that can limit me is time.',
	fixDetails: 'I will take your challenge',
	serviceRange: 'SERVICE RANGE',
	serviceRangeContent: 'We are taking orders no matter their sizes. We can fix your docker, run application on the server, automate your jobs or provide full service by running your app stable, while accelerating development processes.',
	complexity: 'COMPLEXITY',
	complexityContent: 'Due to our wide range of contractors, we can provide more than only infrastructure. Do you need a simple website, or a compounded web application? We will deliver a complete product. Contact us!',
	consulting: 'CONSULTING',
	consultingContent: 'You have a problem choosing the right solution for your product? Want to get help in some of your own problems? Need to outsource some operations tasks? We are ready to handle it!',
	infrastructure: {
		solutions: 'Infrastructure solutions',
		solutionsSubTitle: 'that will meet your expectations.',
		server: {
			title: 'Servers',
			boldBefore: 'Choosing right ',
			highlighted: 'servers',
			boldAfter: ' for your applications.',
			content: ' We will choose servers that match your requirements. It may be cloud, dedicated servers, VPS or even your physical machines.',
		},
		containers: {
			title: 'Containers',
			highlighted: 'Containerize ',
			bold: 'your application',
			content: 'Pack your applications inside containers, and run it anywhere. Do not worry that environment will be ever misconfigured.',
		},
		automation: {
			title: 'Automation',
			highlighted: 'automate',
			boldBefore: 'We will',
			boldAfter: 'your deployments',
			content: 'Quickly build, deploy, and manage applications automatically using CI/CD scripts. Never again deploy through SSH.',
		},
		monitoring: {
			title: 'Monitoring',
			bold: 'Observe your infrastructure through',
			content: 'Error? Bug? Crash? Bad HTTP response? Strange traffic on your sites? You will be the first who know about that.',
		},
		button: 'Contact',
	},
	myServices: 'My Services',
	offers: {
		infrastructureMonitoring: {
			title: 'Infrastructure Monitoring',
			shortDescription: "Keep an eye on your server's stability and security.",
		},
		ciCdAutomation: {
			title: 'CI/CD Automation',
			shortDescription: 'Manual deployments? Large amount of time between releases? Automate your work using CI/CD.',
			modal: {
				shortDescription: 'In past times developers (or sometimes operators) had to spend their time releasing, deploying and updating applications manually. Thanks to process automation that can be implemented by many tools, there are not such need anymore.',
				implementingCiCd: 'Implementing CI/CD is the prime step to greatly enhance your most important DEV/OPS metrics.',
				rollback: {
					label: 'Decrease Mean Time for Recovery (MTTR)',
					description: 'Decrease the time required to recover to a previous version by implementing one-click deployment rollbacks.',
				},
				deploymentFrequency: {
					label: 'Increase Deployment Frequency',
					description: 'Increase the frequency of deployments through simple and easy automation.',
				},
				leadTime: {
					label: 'Improve Lead Time For Changes',
					description: 'Improve the lead time for making changes by changing team workflows and getting fast feedback.',
				},
				changeFailureRate: {
					label: 'Lower Change Failure Rate',
					description: 'Lower the rate of failures during changes by working in small well-tested batches where bugs are easy to identify and fix.',
				},
				howExampleProcessLooksLike: 'How does an example CI/CD process look like?',
				clickHereForPreview: 'Click here',
				previewOfWorkflow: 'for a preview of the CI/CD workflow.',
				myPreferredStack: 'My preferred stack includes Gitlab, Ansible, Docker, and Kubernetes.',
				interestedQuestion: 'Are you interested?',
				contactMe: 'Contact me.',
			},
		},
		containerizationAndK8s: {
			title: 'Containerization and Kubernetes',
			shortDescription: 'Containers can make your infrastructure portable, scalable and maintainable.',
			modal: {
				description: 'Containerization can make your infrastructure portable, easily manageable, and scalable. I have a lot of experience in containerization, including migrating existing applications to container-based infrastructures.',
				advantages: 'Advantages of Containerization',
				scalability: 'Scalability - Well-written containerized applications can be easily scaled using Kubernetes or Openshift. Scaling can be crucial in cost management on cloud infrastructures, especially when the workload on your applications is not constant.',
				portability: 'Portability - In addition to the application, containers store the full environment configuration required to run a specific application. This feature reduces the need for extensive configuration and simplifies the launch of applications to a single command.',
				maintainability: 'Maintainability - Container management is significantly easier. You can store entire images that can be quickly replaced when needed. Container orchestration software (e.g., Kubernetes) provides multiple functionalities to assist you, such as probes, restart on failures, or even automatic recovery of your application when physical cluster servers are down.',
				automation: 'Automation - Containerization is the first step you should take in your infrastructure or CI/CD automation transformation. It unifies your environments and provides another layer of abstraction that ensures the correctness of your environment.',
			},
		},
		infrastructureManagement: {
			title: 'Infrastructure Management',
			shortDescription: 'Full and comprehensive care for your applications.',
			modal: {
				description1: 'Do you have an application that you want to just work? I can provide full infrastructure solution that will just fit your requirements. Stop worrying about stability, server security and scaling of your application. I will take care of everything.',
				description2: '	Or instead you have a couple of linux machines that you need to maintain, or process some action on them? I will do whatever you need.',
				why: 'Why?',
				stability: 'Stability? Always! I can monitor your application, notify you about any issues, and promptly resolve infrastructure-related problems.',
				rightSolution: "The right solution tailored to your needs. Whether your application requires a scalable Kubernetes environment or it's just a simple website.",
				myPreferredStack: 'My preferred stack includes AWS, Docker, and other specifically required tools.',
				interestedQuestion: 'Are you interested?',
				contactMe: 'Contact me.',
			},
		},
		itConsultancy: {
			title: 'IT Consultancy',
			shortDescription: 'Discuss about your concerns and choose best solution for you.',
		},
	},
	technology: {
		description1: 'Main area of my expertise is CI/CD implementations, infrastructure automation and migrations to devops.',
		description2: 'I also have experience with backend development and IT administration.',
		description3: 'Creating stable and future-proof infrastructure (as a code!), automating processes, and improving existing solutions is what I do best.',
		subcontractor: 'Technology is used as a SUBCONTRACTOR',
		contact: 'Contact the selected person to discuss details',
		confirmation: 'Good',
		skills: {
			rhel: {
				description: 'RedHat Enterprise Linux guarantees the stability and secrity of your servers. ',
				info: 'My skills in RHEL and Linux are confirmed by RHCSA and RHCE certifications.',
			},
			ansible: {
				description: 'I also have experience in backend development and IT administration.',
				info: 'I have created a lot of content about this wonderful tool. My automation skills in Ansible are confirmed by the Red Hat Certified Engineer certification.',
			},
			git: {
				description: 'Git is the most popular version control system in the world.',
				info: 'Even though it is used by all programmers, not everyone can use it properly.',
			},
			gitLab: {
				description: 'GitLab is one of the best all-in-one tools. It is a powerful end-to-end software development platform that combines version control system, planning, CI/CD, and many other features. It is also open source!',
				info: 'I have extensive experience with GitLab, including self-managed instances. I used it as the main pillar in my major projects, which include full DevOps transformation and process automation.',
			},
			docker: {
				description: "The world's most popular containerization platform. It can drastically improve the efficiency, maintainability, and simplicity of infrastructure.",
				info: 'Are you having trouble with application isolation or portability? Do you want your app to work in all environments? If the answer is yes, Docker is the right solution for you.',
			},
			kubernetes: {
				description: 'Kubernetes is a container orchestration tool. It is open-source software for automating deployment, scaling, and management of containerized applications.',
				info: 'Do you have trouble managing multiple containers? Do you need simple and efficient deployments? Or do you require reliability and stability in production deployments? This is the right tool for you.',
			},
			sentry: {
				description: 'Sentry is a real-time application performance monitoring and error tracking tool.',
				info: 'It can really help developers understand errors that actually matter. It also provides excellent dashboards for app performance and stability.',
			},
			prometheus: {
				description: 'Prometheus is a monitoring tool that collects metrics and stores them in a time-series database.',
				info: 'Prometheus is a great tool for monitoring and alerting. Data collected by Prometheus can be easily visualized using Grafana.',
			},
			grafana: {
				description: 'Grafana is an open-source analytics and monitoring visualization solution.',
				info: 'Grafana will help you monitor your system and applications by creating meaningful dashboards and charts from data collected by Prometheus or other data sources.',
			},
			aws: {
				description: 'AWS is the largest cloud platform.',
				info: 'It offers reliable, scalable cloud computing ecosystems.',
			},
			symfony: {
				description: 'Symfony is a modern PHP framework with high performance and a set of components.',
				info: 'I have been programming in Symfony for over a year, and even though it is still PHP, I really enjoy writing applications using this framework.',
			},
		},
	},

	interestedForm: 'Are You interested? Fill out the form below!',
	firstName: 'First name',
	lastName: 'Last name',
	terminalLabel: 'Contact with me.',
	phoneNumber: 'Phone number',
	phoneWrong: 'Invalid Number',
	email: 'Email address',
	emailWrong: 'Invalid Email',
	description: 'Your message',
	confirmation1: 'Thank you for contacting me.',
	confirmation2: 'We will be in touch!',
	designedMessage: 'Designed in Figma, created using React and Tailwind-CSS by',
	designedMessageConnector: 'and',
};
