export const projects = [
	{
		techStack: 'React,Linux,PHP,Vue',
		websiteUrl: 'https://example.com',
		type: 'Linuxowa Aplikejszon',
		title: 'Digital Installation',
		videoUrl: 'https://api.marchantweb.com/videos/the-engine.webm',
		description: "An innovative content-driven artwork for The Engine's new headquarters that engages audiences both within and beyond the walls of the new space.",
		details: {
			client: 'Red hat Inc.',
			partner: 'Sosolimited',
			role: 'Linuxowy progamista',
			completed: '2023',
			content: {
				typing: ['npm', 'run', 'case_study'],
				items: [
					{
						type: 'project',
                        navigation:'Home',
						title: "An innovative content-driven artwork for The Engine's new headquarters that engages audiences both within and beyond the walls of the new space.",
						description:
							'The Engine, a Cambridge-based venture fund, was looking for a creative way to showcase the companies and founders that work at their new headquarters, as well as the problems they are solving. They tasked Sosolimited (with me as the lead creative developer) to create a content-driven digitally-rendered artwork that would position The Engine and Tough Tech beyond the walls of the new headquarters, and express the transformative significance of the problems being tackled by the founders and their companies.',
						imageUrl: 'https://marchantweb.com/cdn-cgi/image/width=1974,quality=100,format=auto/https://api.marchantweb.com/images/0b6a7522-f3e0-4ba1-a9a4-f8d59292d96a',
					},
					{
						type: 'project',
                        navigation:'More About Project',
						title: "An innovative content-driven artwork for The Engine's new headquarters that engages audiences both within and beyond the walls of the new space.",
						description:
							'The Engine, a Cambridge-based venture fund, was looking for a creative way to showcase the companies and founders that work at their new headquarters, as well as the problems they are solving. They tasked Sosolimited (with me as the lead creative developer) to create a content-driven digitally-rendered artwork that would position The Engine and Tough Tech beyond the walls of the new headquarters, and express the transformative significance of the problems being tackled by the founders and their companies.',
						imageUrl: 'https://marchantweb.com/cdn-cgi/image/width=1974,quality=100,format=auto/https://api.marchantweb.com/images/0b6a7522-f3e0-4ba1-a9a4-f8d59292d96a',
					},
				],
			},
		},
	},
	{
		techStack: 'React,Linux,PHP,Vue',
		websiteUrl: 'https://example.com',
		type: 'Linuxowa Aplikejszon',
		title: 'Desktop Application',
		videoUrl: 'https://api.marchantweb.com/videos/the-engine.webm',
		description: 'An open-source interactive lighting installation powered by creative coding.',
		details: {
			client: 'Red hat Inc.',
			partner: 'Sosolimited',
			role: 'Linuxowy progamista',
			completed: '2023',
			content: {
				typing: ['npm', 'run', 'case_study'],
				items: [
					{
						type: 'project',
                        navigation:'Home',
						title: "An innovative content-driven artwork for The Engine's new headquarters that engages audiences both within and beyond the walls of the new space.",
						description:
							'The Engine, a Cambridge-based venture fund, was looking for a creative way to showcase the companies and founders that work at their new headquarters, as well as the problems they are solving. They tasked Sosolimited (with me as the lead creative developer) to create a content-driven digitally-rendered artwork that would position The Engine and Tough Tech beyond the walls of the new headquarters, and express the transformative significance of the problems being tackled by the founders and their companies.',
						imageUrl: 'https://marchantweb.com/cdn-cgi/image/width=1974,quality=100,format=auto/https://api.marchantweb.com/images/0b6a7522-f3e0-4ba1-a9a4-f8d59292d96a',
					},
					{
						type: 'project',
                        navigation:'More About Project',
						title: "An innovative content-driven artwork for The Engine's new headquarters that engages audiences both within and beyond the walls of the new space.",
						description:
							'The Engine, a Cambridge-based venture fund, was looking for a creative way to showcase the companies and founders that work at their new headquarters, as well as the problems they are solving. They tasked Sosolimited (with me as the lead creative developer) to create a content-driven digitally-rendered artwork that would position The Engine and Tough Tech beyond the walls of the new headquarters, and express the transformative significance of the problems being tackled by the founders and their companies.',
						imageUrl: 'https://marchantweb.com/cdn-cgi/image/width=1974,quality=100,format=auto/https://api.marchantweb.com/images/0b6a7522-f3e0-4ba1-a9a4-f8d59292d96a',
					},
				],
			},
		},
	},
	{
		techStack: 'React,Linux,PHP',
		websiteUrl: 'https://example.com',
		type: 'Linuxowa Aplikejszon',
		title: 'WebGL Virtual Tour',
		videoUrl: 'https://api.marchantweb.com/videos/the-engine.webm',
		description: "Building a 3D webGL experience with interactive hotspots for ABM's tradeshows and digital marketing endeavors.",
		details: {
			client: 'Red hat Inc.',
			partner: 'Sosolimited',
			role: 'Linuxowy progamista',
			completed: '2023',
			content: {
				typing: ['npm', 'run', 'case_study'],
                items: [
					{
						type: 'project',
                        navigation:'Home',
						title: "An innovative content-driven artwork for The Engine's new headquarters that engages audiences both within and beyond the walls of the new space.",
						description:
							'The Engine, a Cambridge-based venture fund, was looking for a creative way to showcase the companies and founders that work at their new headquarters, as well as the problems they are solving. They tasked Sosolimited (with me as the lead creative developer) to create a content-driven digitally-rendered artwork that would position The Engine and Tough Tech beyond the walls of the new headquarters, and express the transformative significance of the problems being tackled by the founders and their companies.',
						imageUrl: 'https://marchantweb.com/cdn-cgi/image/width=1974,quality=100,format=auto/https://api.marchantweb.com/images/0b6a7522-f3e0-4ba1-a9a4-f8d59292d96a',
					},
					{
						type: 'project',
                        navigation:'More About Project',
						title: "An innovative content-driven artwork for The Engine's new headquarters that engages audiences both within and beyond the walls of the new space.",
						description:
							'The Engine, a Cambridge-based venture fund, was looking for a creative way to showcase the companies and founders that work at their new headquarters, as well as the problems they are solving. They tasked Sosolimited (with me as the lead creative developer) to create a content-driven digitally-rendered artwork that would position The Engine and Tough Tech beyond the walls of the new headquarters, and express the transformative significance of the problems being tackled by the founders and their companies.',
						imageUrl: 'https://marchantweb.com/cdn-cgi/image/width=1974,quality=100,format=auto/https://api.marchantweb.com/images/0b6a7522-f3e0-4ba1-a9a4-f8d59292d96a',
					},
				],
			},
		},
	},
];
