import background from '../../assets/is-main.svg';
import backgroundCorner from '../..//assets/is-corner-1.svg';
import backgroundCorner2 from '../../assets/is-corner-2.svg';

function Image() {
	return (
		<div className='relative flex h-full min-h-[400px] w-full flex-col items-center justify-center border-b-[1px] border-gray-700 border-opacity-60 py-4 md:border-none md:py-0 l:w-1/2'>
			<img width='150' height='150' alt='background corner' className='absolute z-[1] w-1/2 md:w-[350px]' src={background} />
			<img width='150' height='150' alt='background corner' className='absolute left-0 top-0 z-[1] w-1/4 rotate-180 md:w-[135px]' src={backgroundCorner2} />
			<img width='150' height='150' alt='background corner' className='absolute bottom-0 left-0 z-[1] w-1/5 md:w-[135px] ' src={backgroundCorner} />
			<img width='150' height='150' alt='background corner' className='absolute right-0 top-0 z-[1] w-1/5 rotate-180 md:w-[135px]' src={backgroundCorner} />
			<img width='150' height='150' alt='background corner' className='absolute bottom-0 right-0 z-[1] w-1/4 md:w-[135px] ' src={backgroundCorner2} />
		</div>
	);
}

export default Image;
