import { useState, useEffect, useRef } from 'react';
import { getPanels } from './Panels';
import { ServicePanel } from './ServicePanel';
import { TiArrowRight } from 'react-icons/ti';
import { ReactSVG } from 'react-svg';
import Image from './Image';
import useTranslation from '../../customHooks/useTranslation';

function InfrastructureSolutions() {
	const [activePanel, setActivePanel] = useState(null);
	const observerElement = useRef(null);
	const translate = useTranslation();

	const panels = getPanels(translate);

	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting && activePanel === null) {
					setActivePanel(0);
					observer.unobserve(observerElement.current);
				}
			},
			{
				threshold: 0.1,
			}
		);
		if (observerElement.current) {
			observer.observe(observerElement.current);
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		if (activePanel === null) {
			return;
		}
		const intervalId = setInterval(() => {
			setActivePanel(prevPanel => {
				const currentIndex = prevPanel;
				if (currentIndex === panels.length - 1) {
					return 0;
				} else {
					return currentIndex + 1;
				}
			});
		}, 8000);

		return () => clearInterval(intervalId);
	}, [activePanel]);

	return (
		<div ref={observerElement} className='relative mt-56 flex md:min-h-[500px]'>
			<section id='services' className='flex w-full flex-col-reverse text-dark l:flex-row'>
				<Image />
				<div className='flex h-full w-full flex-col items-start justify-center bg-light l:w-[60%] '>
					<div className='w-full px-8 l:px-4 lg:px-8'>
						<p className='py-2 text-3xl font-bold tracking-wide md:text-4xl xl:text-5xl'>{translate.infrastructure.solutions}</p>
						<p className='text-xl font-medium md:text-2xl xl:text-3xl'>{translate.infrastructure.solutionsSubTitle}</p>
						<div className='my-4 flex w-full flex-wrap justify-between border-b-[1px] border-gray-300 md:flex-nowrap md:justify-start md:space-x-2'>
							{panels.map((panel, index) => (
								<div key={panel.id} onClick={() => setActivePanel(index)} className='flex h-fit w-[47%] cursor-pointer items-center justify-center md:w-auto '>
									<div className={`relative flex w-full items-center border-b-[1px] border-transparent text-base md:text-xs lg:text-[14px] ${activePanel === index && ' border-b-primary'}`}>
										<ReactSVG src={panel.icon} className='h-[40px] w-[40px] fill-dark stroke-dark' />
										{panel.slogan}
										<div className={`absolute bottom-0 left-0 border-b-2 border-b-primary transition-all ease-linear ${activePanel === index ? 'w-full duration-[8000ms]' : 'w-0 border-0 duration-0'}`}></div>
									</div>
									{index !== panels.length - 1 && <TiArrowRight size={36} className={`${activePanel > index && 'text-primary grayscale-0'} hidden h-full min-h-[28px] w-full min-w-[28px] grayscale transition-colors duration-300 ease-in-out md:block`} />}
								</div>
							))}
						</div>
						<div>
							{panels.map((panel, index) => (
								<ServicePanel key={panel.id} isActive={index === activePanel} header={panel.header} description={panel.description} translate={translate} />
							))}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default InfrastructureSolutions;
