import { FaGithubSquare, FaLinkedin, FaFacebookSquare, FaInstagramSquare, FaYoutubeSquare, FaPhoneSquareAlt, FaEnvelopeSquare } from 'react-icons/fa';
import SocialIcon from './SocialIcon';
import Photo from '../../assets/SelfPhoto.webp';
import useTranslation from '../../customHooks/useTranslation';

function AboutMe() {
	const translate = useTranslation();
	return (
		<div className='relative mb-72 flex justify-center'>
			<div className='absolute z-[0] -mt-12 h-[130%] w-full -skew-y-2 bg-secondary' />
			<section className='min-h-48 z-[1] flex h-full w-full max-w-[1320px] select-none flex-col items-center justify-center px-6 py-8 text-light md:flex-row md:justify-evenly'>
				<div className='flex w-full flex-col'>
					<div id='about' className='w-full self-start pt-4 text-3xl font-bold md:text-5xl '>
						{translate.whoAmI}
					</div>
					<div className='flex flex-col'>
						<div className='my-4 w-full md:pr-4 md:text-xl xl:text-2xl'>{translate.aboutMe}</div>
						<div className='my-2 flex justify-center text-5xl md:justify-start md:text-4xl '>
							<SocialIcon icon={<FaGithubSquare className='ml-[-0.5rem]' />} link='https://github.com/Reykez' colorClass='hover:text-purple-400' description='Profil na GitHub' />
							<SocialIcon icon={<FaLinkedin />} link='https://www.linkedin.com/in/kamil-pfaff/' colorClass='hover:text-blue-800' description='Profil na LinkedIn' />
							{/*<SocialIcon icon={<FaYoutubeSquare />} link='https://www.google.com/' colorClass='hover:text-red-500' />*/}
							{/*<SocialIcon icon={<FaFacebookSquare />} link='https://www.google.com/' colorClass='hover:text-blue-600' />*/}
							{/*<SocialIcon icon={<FaInstagramSquare />} link='https://www.google.com/' colorClass='hover:text-pink-600' />*/}
							<SocialIcon icon={<FaPhoneSquareAlt />} link='tel:+48533663534' colorClass='hover:text-green-400' description='Zadzwoń: +48 533 663 534' />
							<SocialIcon icon={<FaEnvelopeSquare />} link='mailto:k3pfaff@gmail.com' colorClass='hover:text-red-300' description='Napisz e-mail: k3pfaff@gmail.com' />
						</div>
					</div>
				</div>
				<img width='150' height='150' alt='self photo' className='object-fit mt-12 h-1/2 max-h-[300px] w-full max-w-[300px] self-center rounded-3xl brightness-90 -hue-rotate-[8deg] md:mt-0 md:w-1/4' src={Photo} />
			</section>
		</div>
	);
}

export default AboutMe;
