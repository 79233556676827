export const pl = {
	mainView: {
		name: 'Kamil Pfaff',
		job: 'Inżynier DevOps',
		texts: [
			{
				// plan
				id: 1,
				pre: 'Świadczę usługi',
				text: 'konsultingowe',
				post: 'dla IT.',
			},
			{
				// code
				id: 2,
				pre: 'Zaimplementuję',
				text: 'rozwiązania',
				post: 'których potrzebujesz.',
			},
			{
				// build/test
				id: 3,
				pre: '',
				text: 'Zautomatyzuję',
				post: 'Twoje procesy.',
			},
			{
				// release/deploy
				id: 4,
				pre: '',
				text: 'Skonteneryzuję',
				post: 'Twoje środowisko.',
			},
			{
				// operate
				id: 5,
				pre: 'Będę',
				text: 'zarządzał', // Infrastructure Management
				post: 'Twoją infrastrukturą.',
			},
			{
				// monitor
				id: 6,
				pre: 'Implementuję nowoczesny',
				text: 'monitoring',
				post: 'infrastruktury.',
			},
		]
	},
	contact: 'KONTAKT',
	about: 'O MNIE',
	services: 'USŁUGI',
	technologies: 'TECHNOLOGIE',
	whoAmI: 'O mnie',
	aboutMe: 'Cześć! Nazywam się Kamil i jestem inżynierem DevOps. Głównie zajmuję się zarządzaniem infrastrukturą opartą o Linux oraz kontenery (Kubernetes/Openshift), ale mam także doświadczenie w zakresie programowania backendu. Wierzę, że jedynym co mnie ogranicza jest mój czas.',
	fixDetails: 'Przyjmę Twoje zadanie',
	serviceRange: 'ZAKRES USŁUG',
	serviceRangeContent: 'Przyjmuję zlecenia każdej wielkości. Skonteneryzuję Twoją aplikację, uruchomię oprogramowanie na serwerze czy zautomatyzuję Twoje procesy, bądź kompleksowo zajmę się hostowaniem Twojej aplikacji.',
	complexity: 'KOMPLEKSOWOŚĆ',
	complexityContent: 'Dzięki siatce partnerów, jestem w stanie dostarczać kompleksowe rozwiązania. Potrzebujesz strony internetowej, a może rozbudowanej aplikacji webowej? Przeprowadzę Cię przez cały proces i dostarczę gotowy produkt.',
	consulting: 'KONSULTING',
	consultingContent: 'Nie wiesz jaką technologię wybrać do swojego projektu? Potrzebujesz kogoś, kto odpowie na Twoje pytania? A może chcesz zlecić wyoknanie konkretnego zadania? Pomogę Ci w tym!',
	infrastructure: {
		solutions: 'Infrastruktura',
		solutionsSubTitle: 'która spełni Twoje oczekiwania.',
		server: {
			title: 'Serwery',
			boldBefore: 'Wybiorę najlepsze ',
			highlighted: 'serwery',
			boldAfter: ' dla Twojego zastosowania',
			content: 'Wybierzemy najbardziej odpowiedni hosting dla Twoich aplikacji. Zastosujemy serwery VPS, dedykowane, chmurę albo nawet Twoje fizyczne maszyny.',
		},
		containers: {
			title: 'Kontenery',
			highlighted: 'Skonteneryzuję ',
			bold: 'Twoją aplikację',
			content: 'Zapakujemy Twoją aplikację do kontenera, dzięki czemu będziesz mógł ją wszędzie uruchomić. Już nigdy nie będziesz musiał się przejmować źle skonfigurowanym środowiskiem.',
		},
		automation: {
			title: 'Automatyzacja',
			highlighted: 'Zautomatyzuję ',
			boldBefore: '',
			boldAfter: 'Twoje wydania',
			content: 'Szybkie i automatyczne budowanie, wydawanie i aktualizowanie przy pomocy skryptów CI/CD. Możesz skończyć z wydaniami przez SSH.',
		},
		monitoring: {
			title: 'Monitoring',
			bold: 'Obserwuj swoją infrastrukturę dzięki ',
			highlighted: 'monitoringowi',
			content: 'Błąd? Coś się zepsuło, zła odpowiedź serwera lub dziwny ruch na Twoich serwisach? Będziesz pierwszym, który się o tym dowie.',
		},
		button: 'Kontakt',
	},
	myServices: 'Moje Usługi',
	offers: {
		infrastructureMonitoring: {
			title: 'Monitorowanie infrastruktury',
			shortDescription: "Zawsze będę mieć oko na stabilność i bezpieczństwo Twojego serwera.",
		},
		ciCdAutomation: {
			title: 'Automatyzacja CI/CD',
			shortDescription: 'Ręczne wywdawanie aplikacji lub długi czas potrzebny na wydanie nowej wersji? Zautomatyzuj to używając CI/CD.',
			modal: {
				shortDescription: 'W przeszłości developerzy (a czasem operatorzy) musieli spędzać wiele czasu na ręcznym wydawaniu i ulepszaniu aplikacji. Dzięki zautomatyzowaniu tego procesu, ręczna praca może zostać zastąpiona przez wiele narzędzi.',
				implementingCiCd: 'Zaimplementowanie CI/CD do Twojej aplikacji to pierwszy krok, który znacznie poprawi twoje najważniejsze DEV/OPS-owe wskaźniki.',
				rollback: {
					label: 'Zmniejszenie średniego czasu dezaktualizacji (MTTR)',
					description: 'Zmniejsz czas potrzebny, aby wrócić do poprzedniej wersji aplikacji, poprzez dodanie zarządzania aktualizacjami jednym kliknięciem.',
				},
				deploymentFrequency: {
					label: 'Zwiększ częstotliwość wydawania aktualizacji',
					description: 'Zwiększ częstotliwość wydań, poprzez prostą i łatwą autoatyzację tego procesu',
				},
				leadTime: {
					label: 'Popraw czas realizacji zmian',
					description: 'Zmniejsz czas, potrzebny na wprowadzanie zmian przez otrzymywanie szybkich informacji zwrotnych.',
				},
				changeFailureRate: {
					label: 'Zmniejsz częstotliwość niepowodzenia zmian',
					description: 'Zmniejsz częstotliwość porażki podczas wprowadzania zmian przez pracę w małym i dobrze przetestowanym środowisku, gdzie błędy są łatwe do zidentyfikowania i naprawy.',
				},
				howExampleProcessLooksLike: 'Jak wygląda przykładowy proces CI/CD?',
				clickHereForPreview: 'Naciśnij tutaj',
				previewOfWorkflow: 'aby wyświetlić podgląd procesu.',
				myPreferredStack: 'Stack technologiczny, który preferuję to: Gitlab, Ansible, Docker oraz Kubernetes.',
				interestedQuestion: 'Jesteś zainteresowany?',
				contactMe: 'Skontaktuj się ze mną.',
			},
		},
		containerizationAndK8s: {
			title: 'Konteneryzacja i Kubernetes',
			shortDescription: 'Kontenery mogą sprawić, że twoja infrastruktura jest przenośna, skalowalna i łatwa w utrzymaniu.',
			modal: {
				description: 'Kontenery mogą sprawić, że twoja infrastruktura jest przenośna, skalowalna i łatwa w utrzymaniu. Mam duże doświadczenie, jeżeli chodzi o konteneryzację, włączając w to przenoszenie istniejących aplikacji, na infrastruktury bazowane na kontenerach.',
				advantages: 'Plusy konteneryzacji.',
				scalability: 'Skalowalność - Dobrze napisane aplikacje kontenerowe mogą być łatwo skalowane przy użyciu Kubernetesa czy Openshifta. Może być to kluczowe jeżeli chodzi o zarządzanie kosztami przeznaczonymi na chmurę, w szczególności, gdy obciążenie na aplikacji nie jest stałe.',
				portability: 'Możliwość przenoszenia - Kontenery przechowują pełne środowisko potrzebne do uruchomienia aplikacji. Zmniejsza to potrzebę rozbudowanej konfiguracji, upraszczając uruchomienie do wpisania jednej komendy.',
				maintainability: 'Łatwość w utrzymaniu - Konteneryzacja jest znacznie łatwiejsza w użyciu. Możesz w nich przechowywać całe obrazy, które w razie potrzeby mogą być szybko zastąpione. Oprogramowanie do orkiestracji kontenerów zapewnia wiele przydatnych funkcji, takich jak ponowne uruchamianie w wypadku awarii aplikacji lub serwera.',
				automation: 'Automatyzacja - Konteneryzacja powinna być pierwszym krokiem, który podejmujesz podczas zmiany infrastruktury bądź zmiany aplikacji na automatyzację za pomocą CI/CD. Jednoczy to Twoje środowiska i dodaje dodatkową warstwę abstrakcji, co zapewnia poprawność działania Twojego środowiska.',
			},
		},
		infrastructureManagement: {
			title: 'Zarządzanie infrastrukturami',
			shortDescription: 'Pełna i kompleksowa opieka nad Twoimi aplikacjami.',
			modal: {
				description1: 'Masz aplikację i chcesz aby ona działała? Mogę zapewnić pełne rozwiązanie, które będzie spełniać twoje oczekiwania. Możesz przestać się martwić o stabilność, czy bezpieczeństwo serwera, a także skalowanie Twojej aplikacji. Zajmę się tym wszystkim.',
				description2: 'A może masz parę serwerów stojących na linuxie, które musisz utrzymać lub wykonywać różne działania na nich? Zrobię w tym temacie wszystko co tylko będziesz chciał.',
				why: 'Dlaczego?',
				stability: 'Stabilność? Mogę na bierząco monitorować Twoją aplikację i powiadomić Cię, gdy wystąpią jakieś błędy i zapewnić szybkie ich rozwiązanie.',
				rightSolution: "Zapewnię dobre rozwiązanie, które jest dopasowane do twoich potrzeb, niezależnie od tego, czy twoja aplikacja potrzebuje całego środowiska, czy jest to mała i prosta strona.",
				myPreferredStack: 'Stack technologiczny, który preferuję to: AWS, Docker i inne, specjalnie wymagane do tego narzędzia.',
				interestedQuestion: 'Jesteś zainteresowany?',
				contactMe: 'Skontaktuj się ze mną.',
			},
		},
		itConsultancy: {
			title: 'Doradzanie w kwestii IT',
			shortDescription: 'Porozmawiamy o Twoich obawach lub potrzebach i wybierzemy najlepsze rozwiązanie dla ciebie.',
		},
	},
	technology: {
		description1: 'Moją główną specjalizacją są implementacje CI/CD, konteneryzacja oraz projektowanie, zarządzanie i automatyzacja infrastruktury.',
		description2: 'Mam także doświadczenie z programowaniem aplikacji backendowych oraz administracją systemów (Linux).',
		description3: 'Tworzę stabilne i rozwojowe rozwiązania, automatyzuję procesy, i poprawiam bądź rozwijam istniejące projekty.',
		subcontractor: 'Technologia jest używana jako SUBCONTRACTOR',
		contact: 'Skontaktuj się z wybraną osobą w celu omówienia szczegółów',
		confirmation: 'Dobrze',
		skills: {
			rhel: {
				description: 'RedHat Enterprise Linux gwarantuje stabilność i bezpieczeństwo Twoich systemów.',
				info: 'Moje umiejętności z zakresu Linuxa, w szczególności systemu RHEL potwierdza certyfikacja RHCSA i RHCE.',
			},
			ansible: {
				description: 'Mam także doświadczenie w backendzie i administracji IT.',
				info: 'Stworzyłem wiele Ansible\'owych playbook\'ów. Moje umiejętności z zakresu automatyzacji przy użyciu Ansible są potwierdzone certyfikatem Red Hat Certified Engineer.',
			},
			git: {
				description: 'Git to najpopularniejszy system kontroli wersji na świecie.',
				info: 'Mimo że jest używany przez praktycznie wszystkich programistów, nie każdy potrafi sprawnie się w nim poruszać.',
			},
			gitLab: {
				description: 'GitLab to jedno z najlepszych narzędzi typu "all-in-one". Jest platformą, która łączy w sobie system kontroli wersji, mechanizmy pozwalające na planowanie pracy, implementację CI/CD i wiele innych funkcji. Jest to otwarte oprogramowanie!',
				info: 'Posiadam sporo doświadczenia z GitLab\'em, w tym z samodzielnie zarządzanymi instancjami. Wykorzystywałem go jako filar w moich projektach, obejmujących pełną transformację do DevOps i automatyzację CI/CD.',
			},
			docker: {
				description: 'To najpopularniejsza platforma do konteneryzacji na świecie. Jej wykorzystanie drastycznie poprawia efektywność, zarządzalność i prostotę infrastruktury.',
				info: 'Masz problem z przenośnością aplikacji? Chcesz, żeby Twoja aplikacja działała we wszystkich środowiskach? Jeśli tak, Docker to właściwe rozwiązanie dla Ciebie.',
			},
			kubernetes: {
				description: 'To narzędzie do orkiestracji kontenerów. Jest to otwarte oprogramowanie służące do automatyzacji zarządzania i skalowania skonteneryzowanymi aplikacjami.',
				info: 'Masz problem z zarządzaniem wieloma kontenerami? Potrzebujesz prostego i efektywnego skalowania? A może wymagasz niezawodności i stabilności swoich aplikacji? To narzędzie jest właściwe dla Ciebie.',
			},
			sentry: {
				description: 'Sentry to narzędzie umożliwiające śledzenie błędów Twojej aplikacji.',
				info: 'Znacząco pomoże Twoim deweloperom zrozumieć błędy aplikacji. Pozwala w łatwy sposób analizować wydajność i stabilność aplikacji.',
			},
			prometheus: {
				description: 'Narzędzie do monitorowania serwerów, które zbiera metryki i zapisuje je w bazie danych.',
				info: 'Prometheus to świetne narzędzie do monitorowania i alarmowania. Dane zbierane przez Prometheusa można łatwo wizualizować za pomocą Grafany.',
			},
			grafana: {
				description: 'Otwarte narzędzie do wizualizacji i monitorowania.',
				info: 'Grafana pomoże Ci w monitorowaniu systemów i aplikacji. Dzięki niej utworzysz wykresy, które zwizualizują dane zbierane z różnych źródeł - takich jak Prometheus.',
			},
			aws: {
				description: 'Największa platforma chmurowa.',
				info: 'Niezawodne i skalowalne systemy w chmurze.',
			},
			symfony: {
				description: 'Nowoczesny i rozbudowany framework PHP o wysokiej wydajności.',
				info: 'W Symfony programowałem nieco ponad rok, i pomimo, że to dalej PHP, to jednak aplikacje tworzy się w nim całkiem przyjemnie.',
			},
		},
	},
	interestedForm: 'Jesteś zainteresowany? Skontaktuj się ze mną!',
	firstName: 'Imię',
	lastName: 'Nazwisko',
	terminalLabel: 'Skontaktuj się ze mną.',
	phoneNumber: 'Numer telefonu',
	phoneWrong: 'Niepoprawny numer telefonu',
	email: 'Adres email',
	emailWrong: 'Niepoprawny adres email',
	description: 'Twoja wiadomość',
	confirmation1: 'Dziękuje za skontaktowanie się ze mną.',
	confirmation2: 'Będziemy w kontakcie!',
	designedMessage: 'Zaprojektowane w Figmie, stworzone przy użyciu React i Tailwind-CSS przez',
	designedMessageConnector: 'i',
};
