import { Server, Automation, Eye, Containerization } from '../OffersGallery/Images';

export const getPanels = translate => [
	{
		id: 'Server',
		icon: Server,
		slogan: translate.infrastructure.server.title,
		header: (
			<>
				{translate.infrastructure.server.boldBefore}
				<span className='font-semibold text-primary'>{translate.infrastructure.server.highlighted}</span>
				{translate.infrastructure.server.boldAfter}
			</>
		),
		highlighted: 'Server',
		description: translate.infrastructure.server.content,
	},
	{
		id: 'Containerization',
		icon: Containerization,
		slogan: translate.infrastructure.containers.title,
		header: (
			<>
				<span className='font-semibold text-primary'>{translate.infrastructure.containers.highlighted}</span>
				{translate.infrastructure.containers.bold}
			</>
		),
		highlighted: 'Containerization',
		description: translate.infrastructure.containers.content,
	},
	{
		id: 'Automation',
		icon: Automation,
		slogan: translate.infrastructure.automation.title,
		header: (
			<>
				{translate.infrastructure.automation.boldBefore} <span className='font-semibold text-primary'>{translate.infrastructure.automation.highlighted}</span> {translate.infrastructure.automation.boldAfter}
			</>
		),
		highlighted: 'Automation',
		description: translate.infrastructure.automation.content,
	},
	{
		id: 'Monitoring',
		icon: Eye,
		slogan: translate.infrastructure.monitoring.title,
		header: (
			<>
				{translate.infrastructure.monitoring.bold} <span className='font-semibold text-primary'>{translate.infrastructure.monitoring.highlighted}</span>
			</>
		),
		highlighted: 'Monitoring',
		description: translate.infrastructure.monitoring.content,
	},
];
