import { ImageIndexProvider } from '../contextApi/ImageIndexContext';
import Navbar from '../components/Navbar/Navbar';
import MainView from '../components/MainView/MainView';
import WorkScope from '../components/WorkScope/WorkScope';
import InfrastructureSolutions from '../components/InfrastructureSolutions/InfrastructureSolutions';
import OffersGallery from '../components/OffersGallery/OffersGallery';
import Technologies from '../components/Technologies/Technologies';
import AboutMe from '../components/AboutMe/AboutMe';
import Form from '../components/Contact/Contact';
import Footer from '../components/Footer/Footer';

function MainPage() {
	return (
		<div className='bg-dark'>
			<ImageIndexProvider>
				<Navbar />
				<MainView />
				<WorkScope />
				<InfrastructureSolutions />
				<div className='relative overflow-visible'>
					<div className="absolute h-full w-full overflow-visible bg-[url('/src/assets/background2.svg')] bg-cover opacity-25 md:bg-fixed" />
					<div>
						<OffersGallery />
						<Technologies />
					</div>
				</div>
				{/*<PortfolioSlider />*/}
				<AboutMe />
				<Form />
				<Footer />
			</ImageIndexProvider>
		</div>
	);
}
//
export default MainPage;
