import Typewriter from "typewriter-effect";
import MainLogo from "./MainLogo";
import "./mainHeader.css";
import useTranslation from "../../customHooks/useTranslation";

import { useRef, useState, useEffect } from "react";

function MainView() {
  const [preText, setPreText] = useState("");
  const [postText, setPostText] = useState("");
  const [displayMode, setDisplayMode] = useState("inline");
  const preTextRef = useRef();
  const updateText = useRef();
  const postTextRef = useRef();
  const animatedTextRef = useRef();
  const parentUpdateText = useRef();
  const [fadeState, setFadeState] = useState(1);
  const [loopId, setLoopId] = useState(1);
  const [typewriterKey, setTypewriterKey] = useState(0);
  const translate = useTranslation();

  useEffect(() => {
    setTypewriterKey((prevKey) => prevKey + 1);
  }, [translate.mainView.texts]);

  useEffect(() => {
    function checkWidth() {
      const textWidth =
        preTextRef.current.offsetWidth +
        postTextRef.current.offsetWidth +
        animatedTextRef.current.offsetWidth;
      const parentWidth = parentUpdateText.current.offsetWidth;

      if (textWidth > parentWidth) {
        setDisplayMode("block");
      } else {
        setDisplayMode("inline");
      }
    }
    checkWidth();
    const mutationObserver = new MutationObserver(checkWidth);
    mutationObserver.observe(updateText.current, {
      childList: true,
      subtree: true,
    });
    window.addEventListener("resize", checkWidth);

    return () => {
      mutationObserver.disconnect();
      window.removeEventListener("resize", checkWidth);
    };
  }, []);

  async function changeText(pre, post, id) {
    setFadeState(0);
    await timeout(320);
    setPreText(pre);
    setPostText(post);
    await timeout(320);
    setFadeState(1);
    setLoopId(id);
  }

  function run(typewriter) {
    translate.mainView.texts.forEach((element) => {
      typewriter
        .callFunction(() => {
          changeText(element.pre, element.post, element.id);
        })
        .pauseFor(850)
        .typeString(element.text)
        .pauseFor(2500)
        .deleteAll();
    });
    typewriter.start();
  }

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  return (
    <div className="relative flex h-screen max-w-full flex-col items-center justify-center pt-16">
      <div className="absolute h-full w-full bg-[url('/src/assets/background.svg')] bg-cover opacity-25 md:bg-fixed" />
      <div className="l:mt-0 z-[1] mt-36 w-full max-w-[1320px] p-6 pb-24">
        <div
          ref={parentUpdateText}
          className="l:flex-row relative flex w-full flex-col justify-between"
        >
          <div className="l:w-[125%] l:min-w-[500px] l:justify-start flex w-full items-center justify-center xl:min-w-[700px]">
            <div className="l:text-left text-center">
              <p className="text-light l:text-[72px] whitespace-nowrap text-[56px] font-extrabold leading-10">
                {translate.mainView.name}
              </p>
              <p className="text-light l:mb-6 l:text-[40px] mb-4 text-[28px] font-semibold tracking-wider">
                {translate.mainView.job}
              </p>
              <p
                className={`text-light xs:text-base l:text-[24px] my-4 inline max-w-full whitespace-nowrap text-[16px] font-medium md:max-w-[60%] md:whitespace-pre-wrap md:text-[20px]`}
                ref={updateText}
              >
                <span
                  ref={preTextRef}
                  className={`fadeable ${
                    fadeState == 0 ? "fade-left-from" : ""
                  }`}
                >
                  {preText}&nbsp;
                </span>
                <span
                  ref={animatedTextRef}
                  className={`text-primary ${displayMode} mx-auto w-fit`}
                  key={typewriterKey}
                >
                  <Typewriter
                    options={{
                      cursor: "",
                      loop: true,
                    }}
                    onInit={(typewriter) => {
                      run(typewriter);
                    }}
                  />
                </span>
                <span
                  ref={postTextRef}
                  className={`fadeable ${
                    fadeState == 0 ? "fade-right-from" : ""
                  }`}
                >
                  &nbsp;{postText}
                </span>
              </p>
            </div>
          </div>
          <div className="custom-shadow l:mt-0 mt-12 flex w-full items-center justify-center">
            <div className="w-3/4">
              <MainLogo state={loopId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainView;
